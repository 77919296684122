<template>
  <div class="companyNews my_breadcrumb_1">
<!--    <el-breadcrumb separator-class="el-icon-arrow-right">-->
<!--      &lt;!&ndash;      <el-breadcrumb-item>首页</el-breadcrumb-item>&ndash;&gt;-->
<!--      &lt;!&ndash;      <el-breadcrumb-item>新闻动态</el-breadcrumb-item>&ndash;&gt;-->
<!--      <el-breadcrumb-item>公司新闻</el-breadcrumb-item>-->
<!--    </el-breadcrumb>-->
    <div>
      <div id="back8Id">
        <transition name="slide-fade">
          <div v-if="showBack8" class="back8_1">最新消息丨Latest News</div>
        </transition>
      </div>
      <div v-for="(item, index) in caseList" :key="index">
      <div class="back8_5">
      <div class="back8_2">
          <div class="back8_4" @click="toSub(item)">
            <div class="back8_3">{{ item.createTime }}</div>
            <!--            //标题-->
            <!--              <div>{{ item.newsTitle }}</div>-->
            <div class=" text-ellipsis" v-html="item.newsText"></div>
<!--            <div>-->
<!--              <img :src="item.newsPicture"/>-->
<!--            </div>-->
            <div v-for="(obj, index) in item.filNameList" :key="index">
              <a :href="obj.url" rel="noreferrer" target="_blank" @click.stop>{{ obj.fileName }}</a>
            </div>
          </div>
        </div>
      </div>
      </div>

    </div>
    <!--    <div class="my_div1">-->
    <!--      <el-row class="my_el_row">-->
    <!--        <el-col-->
    <!--          :span="8"-->
    <!--          @click.native="toSub(item)"-->
    <!--          v-for="(item, index) in caseList"-->
    <!--          :key="index"-->
    <!--          style="width: 100%; margin-bottom: 20px;" class="my_el_row_col">-->

    <!--          <el-card :body-style="{ padding: '0px' }" style="">-->
    <!--            &lt;!&ndash;            <div style="height:900px;background-size:100% 100% !important;background-repeat: no-repeat" class="swiper-img my_height"&ndash;&gt;-->
    <!--            &lt;!&ndash;                 v-lazy:background-image="iteam.picture"></div>&ndash;&gt;-->
    <!--           <div style="display: flex;flex-direction: row;align-items: center;">-->
    <!--            <div>-->
    <!--              <img-->
    <!--                :src="item.newsPicture"-->

    <!--                class="image"-->
    <!--                style="width: 300px;background-size: cover"-->
    <!--              />-->
    <!--            </div>-->
    <!--              <div style="padding:0 14px;">-->
    <!--              <a-->
    <!--                style="padding-left: 20px;font-size: 1.5rem;font-weight: 600"-->
    <!--                class="my_span"-->
    <!--              >{{ item.newsTitle }}-->
    <!--              </a>-->
    <!--              <div class="bottom clearfix" style="padding-top: 0px;width: 90%;">-->
    <!--                &lt;!&ndash; <time style="padding-left: 20px;font-size: 20px" class="time">{{ item.newsText }}</time> &ndash;&gt;-->
    <!--                <div-->
    <!--                  style="padding-left:1.7rem;"-->
    <!--                  class="time text-ellipsis"-->
    <!--                  v-text="$options.filters.msg(item.newsText)"-->
    <!--                ></div>-->
    <!--              </div>-->
    <!--                <div  style="float: right;margin-top: 1rem;margin-right: 0.8rem;">{{ item.createTime }}</div>-->
    <!--            </div>-->
    <!--           </div>-->
    <!--          </el-card>-->
    <!--        </el-col>-->
    <!--      </el-row>-->
    <!--    </div>-->

      <!-- 分页组件 -->
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[6, 10, 15, 100]"
        :page-size="pageSize"
        :total="total"
        layout="total, prev, pager, next, jumper">
      </el-pagination>

  </div>

</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      currentDate: new Date(),
      caseList: [],
      total: 0,
      currentPage: 1,
      pageSize: 6, // 每页显示条数
      showBack8: false,
    };
  },
  filters: {
    msg: function (msg) {
      let msg1 = msg.replace(/<(p|div)[^>]*>(<br\/?>|&nbsp;)<\/\1>/gi, '\n')
        .replace(/<br\/?>/gi, '\n').replace(/<[^>/]+>/g, '')
        .replace(/(\n)?<\/([^>]+)>/g, '').replace(/\u00a0/g, ' ')
        .replace(/&nbsp;/g, ' ').replace(/<\/?(img)[^>]*>/gi, '')
        .replace(/&amp;/g, "&").replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&#39;/g, "\'")
        .replace(/&quot;/g, "\"")
        .replace(/<\/?.+?>/g, "")
      return msg1;
    }
  },
  created() {
    this.getList(1);

    // axios.post('http://116.205.170.48:8081/api/ago/companyNewsList')
    //     .then(response => {
    //       this.caseList = response.data.rows;
    //       // console.log(this.caseList)
    //       // this.loading = false;
    //       // this.userData = response.data;
    //     })
    //     .catch(error => {
    //       console.error('There was an error!', error);
    //     });
  },
  computed: {},
  mounted() {
    this.showTransition()
  },
  methods: {
    showTransition() {
      // 创建一个IntersectionObserver实例
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          // 检查当前entry（元素）是否至少50%可见
          if (entry.isIntersecting) {
            // 如果是，执行特定操作，例如：
            if (entry.target.id == 'back8Id') {
              this.showBack8 = true
            }
            // 你可以在这里设置特定于该元素的属性，比如：
            // this.someSpecificProperty[entry.target.id] = true;

            // 如果只需要在元素第一次变为50%可见时触发一次，可以停止观察该元素
            this.observer.unobserve(entry.target);
          }
        });
      }, {
        root: null, // 观察相对于视口
        threshold: 0.5 // 当元素50%可见时触发
      });

      // 假设你有一个包含所有需要观察的元素ID的数组
      const elementIds = ['back8Id']; // 添加更多ID

      // 遍历数组，为每个元素设置观察
      elementIds.forEach(id => {
        const element = document.getElementById(id);
        if (element) {
          this.observer.observe(element);
        }
      });
    },
    toSub(item) {
      localStorage.setItem('item', JSON.stringify(item));

      debugger
      this.$router.push({path: "/CompanyNewsLevel", query: {type: '公司新闻',}});
    },
    // 页面大小改变时触发
    handleSizeChange(val) {
      this.pageSize = val;
      // 重新加载数据
      this.getList(val)
    },
    // 当前页改变时触发
    handleCurrentChange(val) {
      this.currentPage = val;
      // 重新加载数据
      this.getList(val)
    },
    getList(num1) {
      var params = new URLSearchParams();
      params.append('pageNum', num1);
      this.$http.get("companyNewsList?pageNum=" + num1 + "&pageSize=" + this.pageSize + "").then((response) => {
        this.caseList = response.data.rows;
        this.caseList.map(item =>{
          let filNameList = []
          item.newsPictures.map(obj =>{
            const dateEndIndex = obj.indexOf('/', obj.indexOf('upload/') + 'upload/'.length + 1) + 1;
            const secondDateEndIndex =obj.indexOf('/', dateEndIndex);
            const thirdDateEndIndex = obj.indexOf('/', secondDateEndIndex + 1);
            // 从日期部分的下一个'/'开始截取
             filNameList.push({fileName: obj.slice(thirdDateEndIndex + 1),url:obj});
             item.filNameList = filNameList
          })

        })
        this.total = response.data.total;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.back8_1 {
  padding: 0 135px 0px 135px;
  font-size: 35px;
  font-weight: bold;
}

.back8_2 {
  margin-top: 20px;
  background-color: rgba(115, 109, 109, 0.07);
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.back8_3 {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 10px;
}

.back8_4 {
  display: flex;
  flex-direction: column;
  padding: 25px 85px;
}
.back8_5 {
  padding: 0 135px 0 50px;
}
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 2s ease;
}

.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */
{
  transform: translateX(-20%);
  opacity: 0;
}

.slide-fade-enter-to, .slide-fade-leave {
  transform: translateX(0);
  opacity: 1;
}

.el-pagination {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.companyNews {
  margin-top: 0px;
  padding: 30px 0;

  .el-breadcrumb {
    line-height: normal !important;
    width: 100%;
    font-size: 1.25rem;
    padding: 1.25rem 0;
    border-bottom: 1px solid #928e8e;
    display: flex;
    margin-left: 0;
  }

  .my_div1 {
    width: 100%;
    height: auto;
    margin: 10px;
      //margin-top: 90px;
    margin-left: 0rem;
    min-height: 500px;
  }
}

::v-deep.el-breadcrumb > span {
  display: flex;
}

::v-deep.el-breadcrumb > span > i {
  padding: 1px 0 0 0;
}


.bottom {
  margin-top: 13px;
  //line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.my_span {
  cursor: pointer;
  height: 3.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 86rem;
  margin-bottom: 1.85rem;
}

.text-ellipsis {
  color: black;
  font-size: 16px;
  text-align: justify;
  width: 100%;
  // height: 14.5rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
</style>

