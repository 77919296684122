<template>

  <div class="footer">

    <div class="footer_2">
      <div class="footer_4">
        <el-popover placement="left" trigger="hover">
          <img class="imgSize_2" :src="pcikerFormatter()"/>
          <img slot="reference" class="imgSize_1" src="@/assets/img/wechat.png"/>
        </el-popover>
      </div>
      <div class="footer_4">
        <el-popover placement="left" trigger="hover" popper-class="monitor-yt-popover">
          <div class="imgSize_3">Tel：15202352413</div>
          <img slot="reference" class="imgSize_1" src="@/assets/img/phone.png"/>
        </el-popover>
      </div>

      <div class="footer_5" @click="goTop">
        <img class="imgSize_1" src="@/assets/img/goTop.png"/>
      </div>
    </div>


    <div class="footer-content">
      <div class="footer-content my_footer-content">
        <ul class="content-nav">
          <div class="div3">
            <li class="div2">
              <p>关于我们</p>
              <div class="div1" @click="navigateToIntroduction('companyIntroduction','/CompanyIntroduction')">
                <span>公司介绍</span>
              </div>
              <div class="div1" @click="navigateToIntroduction('companyVision','/CompanyVision')">
                <span>公司愿景</span>
              </div>
              <div class="div1" @click="navigateToIntroduction('teamIntroduction','/TeamIntroduction')">
                <span>团队介绍</span>
              </div>
            </li>
            <li>
              <p>解决方案</p>

              <!--              <div class="div1" @click="navigateToIntroduction('','/CloudBasedSolutions')">-->
              <!--                <span>汽车行业</span>-->
              <!--              </div>-->
              <div class="div1" @click="navigateToIntroduction('','/LocalPlan')">
                <span>电子电路</span>
              </div>
              <!--          <router-link to="/WMSView">-->
              <!--            <span>WMS</span>-->
              <!--          </router-link>-->
              <div class="div1" @click="navigateToIntroduction('','/SmartDevices')">
                <span>智能设备</span>
              </div>
            </li>
            <li>
              <p>手册丨新闻</p>
              <div class="div1" @click="navigateToIntroduction('','/CompanyNews')">
                <span>最新消息</span>
              </div>
              <!--              <router-link to="/IndustryNews">-->
              <!--                <span>行业新闻</span>-->
              <!--              </router-link>-->
            </li>
            <li>
              <p>加入我们</p>
              <div class="div1" @click="navigateToIntroduction('','/RecruitingTalents')">
                <span>招贤纳士</span>
              </div>
              <!--              <router-link to="/RecruitingTalentsCampus">-->
              <!--                <span>校园招聘</span>-->
              <!--              </router-link>-->
            </li>
            <li>
              <!--          <router-link class="my-router-link" to="/ContactUs">-->
              <p>联系我们</p>
              <!--          </router-link>-->
              <div class="div1" @click="navigateToIntroduction('','/ContactUs')">
                <span>业务：叶先生</span>
              </div>
              <div class="div1" @click="navigateToIntroduction('','/ContactUs')">
                <span>邮箱：baisp01@163.com</span>
              </div>
              <div class="div1" @click="navigateToIntroduction('','/ContactUs')">
                <span>电话：15202352413</span>
              </div>
              <!--              <router-link to="/ContactUs">-->
              <!--                <span class="">市场：叶先生</span>-->
              <!--              </router-link>-->

              <!--          <router-link to="/ContactUs">-->
              <!--            <span>地址：重庆市南岸区铜元局街道亚太路1号1幢5层19号</span>-->
              <!--          </router-link>-->
            </li>
            <li>
              <p>友情链接</p>
              <div class="div1" @click="electric()">
                <span>中国电子电路行业协会</span>
              </div>
              <div class="div1" @click="chinaCar()">
                <span>IPC中国官网</span>
              </div>
            </li>
          </div>
        </ul>
        <div class="public-account">
          <!--          <div class="imgs1" >-->
          <!--            &lt;!&ndash; <img src="./assets/img/ercode.png" alt=""> &ndash;&gt;-->
          <!--            <img style="width: 7rem" src="@/assets/img/sph.png">-->
          <!--            <span style="margin-top: 0.5rem;">视频号</span>-->
          <!--          </div>-->
          <div class="imgs">
            <!-- <img src="./assets/img/ercode.png" alt=""> -->
            <img style="width: 7rem;" src="@/assets/image.png">
            <span style="margin-top: 0.5rem">公众号</span>
          </div>
        </div>
      </div>
      <!-- <div style="border: 1px red solid;width: 130px;height: 130px;float: right;margin-left: 200px;"></div> -->
      <div class="copyright text-center">
        <div :class="weChat ? 'div4_weChat' : 'div4'">
          <div> © 2024-2025 版权所有：佰世普信息技术（重庆）有限公司&nbsp;</div>
          <div>
            <a class="footer_9" target="_blank"
               href="https://www.baisptech.com">www.baisptech.com</a> 丨
            <a class="footer_9" target="_blank"
               href="https://beian.miit.gov.cn/#/Integrated/index">渝ICP备2024043663号-1</a>
            丨 <img style="width: 1rem;" src="@/assets/img/police.png">
            <a href="https://beian.mps.gov.cn/#/query/webSearch?code=50010802006376" rel="noreferrer" target="_blank"
               class="footer_9">&nbsp;渝公网安备50010802006376</a>

          </div>

        </div>
        <!-- <router-link class="my-router-link" to="/ContactUs">
          <span>&nbsp;&nbsp;&nbsp;&nbsp;备案号：</span>
        </router-link> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      inputText: '',
      drawer: true,
      drawerShow: false,
      arrow: '>',
      isRetract: 'false',
      count: [1],
      weChat: false,
    }
  },
  mounted() {

    function isWeChatBrowserOnAndroid() {
      var ua = window.navigator.userAgent.toLowerCase();
      // 检查userAgent是否包含'micromessenger'（微信）和'android'
      return ua.match(/micromessenger/i) && ua.match(/android/i);
    }

// 如果是微信浏览器并且在安卓设备上，启用特定样式
    if (isWeChatBrowserOnAndroid()) {
      this.weChat = true;
    }


  },
  methods: {
    goTop() {
      // 使用 window.scrollTo 方法滚动到页面顶部
      window.scrollTo({
        top: 0, // 垂直滚动到顶部
        behavior: 'smooth' // 平滑滚动
      });
    },
    pcikerFormatter() {
      return require(`@/assets/img/wechatCode.jpg`);
    },
    navigateToIntroduction(id, path) {
      const routeParams = {path: path};
      if (id) {
        routeParams.query = {scrollToTop: id};
      }
      this.$router.push(routeParams);
    },
    load() {
      this.count = [1, 5]
    },
    electric() {
      // alert("1212321")
      // window.location.href = 'http://www.baidu.com'
      const url = 'https://www.cpca.org.cn';
      window.open(url, '_blank');
    },
    chinaCar() {
      // alert("1212321")
      // window.location.href = 'http://www.baidu.com'
      const url = 'http://www.ipc.org.cn';
      window.open(url, '_blank');
    },
    internetCar() {
      // alert("1212321")
      // window.location.href = 'http://www.baidu.com'
      const url = 'https://www.iatfglobaloversight.org/'
      window.open(url, '_blank')
    },
    buttonHidden() {
      this.drawerShow = !this.drawerShow
      // this.drawer = true
      // this.isRetract = !this.isRetract
      // if (this.isRetract){
      //   this.arrow = '>'
      // }else{
      //   this.arrow = '<'
      // }
    }
  }
}
</script>

<style scoped lang="scss">

.div4 {
  padding: 10px 0;
  font-size: 17px;
  font-weight: normal;
  color: #fff;
  width: 100%;
  border-top: #fff 1px solid;
  text-align: left;
  display: flex
}

.div4_weChat {
  padding: 10px 0;
  font-size: 17px;
  font-weight: normal;
  color: #fff;
  width: 100%;
  border-top: #fff 1px solid;
  text-align: left;
  display: block;
}

.div3 {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.div2 {
  padding: 0px !important;
}

.div1 {
  cursor: pointer;
  font-weight: normal
}

::v-deep .el-textarea__inner {
  border-radius: 0 0 1rem 1rem;
}

::v-deep .el-textarea__inner:focus {
  border-color: #C0C4CC;
}

::v-deep .el-textarea__resize-handle {
  display: none;
}

.my_footer-content {
  padding: 0 135px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.imgs1 {
  margin-right: 40px;
  text-align: center;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
}

.imgs {
  text-align: center;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
}

.footer {
  width: 100%;
  font-weight: 600;
  //margin-top: 1rem;
  // overflow: hidden;
  //background-color: #a1c7f3;
  //background-color: #14679f;
  // position: sticky;

  ::v-deep .el-card__body {
    padding: 0;
  }

  &-content {
    background-color: black;
    margin: 0 auto;
    padding-top: 10px;

    // display: flex;
    // justify-content: space-between;

    .content-nav {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      li {
        display: flex;
        flex-direction: column;
        //justify-content: center;
        align-items: flex-start;
        font-weight: 600;

        p {
          font-size: 18px;
          color: #ffffff;
          // color: #d4edff;
        }

        span {
          //color: #f7f7f7;s
          color: #ffffff;
          // font-weight: 500;
          font-size: 15px;
          padding: 10px 0;
        }
      }
    }
  }

  .copyright {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
    padding: 0 135px;
    //background: #125688;
    width: 100%;
    // position: fixed;
    // bottom: 0;

    span {
      color: #ffffff;
      //color: #fff;
      line-height: 30px;
      font-size: 15px;
    }
  }
}

.footer_2 {
  position: fixed;
  top: 65%;
  right: 0;
  z-index: 999;
}

.footer_4 {
  background-color: black;
}

.footer_5 {
  cursor: pointer;
  background-color: black;
  margin-top: 20px;
}

.footer_9 {
  color: #ffffff;
  font-weight: normal;
  text-decoration: none;
}

.imgSize_1 {
  padding: 10px;
  width: 50px;
  height: 50px;
}

.imgSize_2 {
  width: 200px;
  height: 200px;
}

.imgSize_3 {
  color: #ffffff;
  display: flex;
  align-items: center;
  font-size: 22px;
  justify-content: center;
  width: 200px;
  height: 25px;
  padding: 0px !important;
}

.my_span {
  cursor: pointer;
  height: 40px;
}

.container {
  margin: 0 auto;
  max-width: 100%;
  padding: 0 0px;
  box-sizing: border-box;
}

.router-link-active {
  text-decoration: none;
}

.my-router-link {
  .my_p {
    font-size: 20px;
    color: #addbfa;
    font-weight: 600;
    padding: 0px 0;
  }
}

a {
  text-decoration: none;
  padding: 0px 0;
  color: #f7f7f7;
  font-weight: 300;
  padding: 5px 0;
}

.public-account {
  color: #fff;
  display: flex;
  flex-direction: row;
  //margin-right: 0rem;
  justify-content: space-between;
}

//::v-deep>.el-drawer__container {
//  position: relative;
//  left: 0;
//  right: 0%;
//  top: 80%;
//  bottom: 0%;
//  height: 20%;
//  width: 100%;
//}
.drawer-modify {
  border-radius: 10px;
  top: 40%;
  height: 12rem;
  left: 95.6%;
}

::v-deep .el-drawer {
  border-radius: 10px;
  background-color: #409EFF; /* 浅蓝色背景 */
}

/* 如果有需要修改遮罩层样式 */
::v-deep .el-drawer__mask {
  background-color: rgba(0, 0, 0, 1); /* 半透明黑色背景 */
}

.drawe-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: white;
}

.divider {
  height: 0.1rem;
  width: 0.1rem;
  border-left: black 1px solid;
}

@media (max-width: 576px) {
  .footer {
    min-height: 5vh;
  }

}
</style>
<style>
.el-popper .popper__arrow, .el-popper .popper__arrow::after {
  position: absolute !important;
  display: block !important;
  width: 0 !important;
  height: 0 !important;
  border-color: transparent !important;
  border-style: solid !important;
}

.monitor-yt-popover {
  background-color: black !important;
  border-color: black !important;
}

/*.el-popper[x-placement^=left] .popper__arrow::after {*/
/*    border-left-color: #ff0000;*/
/*}*/
/*.el-popper[x-placement^=left] .popper__arrow {*/
/*    border-left-color: #000022;*/
/*}*/
/*.el-popper[x-placement^=right] .popper__arrow::after {*/
/*    border-right-color: #000022;*/
/*}*/
/*.el-popper[x-placement^=right] .popper__arrow {*/
/*    border-right-color: #000022;*/
/*}*/

</style>
