<template>
  <div class="RecruitingTalents my_breadcrumb_1">
    <!--    <el-breadcrumb separator-class="el-icon-arrow-right my_el-breadcrumb">-->
    <!--&lt;!&ndash;      <el-breadcrumb-item>首页</el-breadcrumb-item>&ndash;&gt;-->
    <!--&lt;!&ndash;      <el-breadcrumb-item>招贤纳士</el-breadcrumb-item>&ndash;&gt;-->
    <!--      <el-breadcrumb-item>社会招聘</el-breadcrumb-item>-->
    <!--    </el-breadcrumb>-->
    <div class="my_div1">
      <!--      <el-carousel-->
      <!--        style="margin-bottom: 15px"-->
      <!--        :interval="5000"-->
      <!--        arrow="never"-->
      <!--        indicator-position="none"-->
      <!--        height="24rem"-->
      <!--        autoplay="false"-->
      <!--      >-->
      <!--        <el-carousel-item-->
      <!--          v-for="(item, index) in caseList"-->
      <!--          :key="index"-->
      <!--          style="height: 24rem !important"-->
      <!--        >-->
      <!--          &lt;!&ndash; <h3>{{ item.value5 }}</h3> &ndash;&gt;-->
      <!--          <div-->
      <!--            style="-->
      <!--              height: 100%;-->
      <!--              width: 100%;-->
      <!--             background-size: 100% 100%;-->
      <!--              background-repeat: no-repeat;-->
      <!--            background-position: center;-->
      <!--              "-->
      <!--            class="swiper-img my_height"-->
      <!--            v-lazy:background-image="item.photo"-->
      <!--          ></div>-->
      <!--        </el-carousel-item>-->
      <!--      </el-carousel>-->
      <div id="back9Id">
        <transition name="slide-fade">
          <div v-if="showBack9" class="back9_1">加入我们丨Join US</div>
        </transition>
      </div>
      <div v-for="(item,index) in tableData" :key="index" class="back9_3">
        <div @click="toggleExpand(index)">
          <el-descriptions :title="item.jobTitle" :column="4" class="back9_4">
            <el-descriptions-item label="发布日期">{{ item.updateTime ? item.updateTime :item.createTime  }}</el-descriptions-item>
            <el-descriptions-item label="工作地点">{{ item.workAddress }}</el-descriptions-item>
            <el-descriptions-item label="招聘人数">{{ item.numberPeople }}</el-descriptions-item>
            <el-descriptions-item label="岗位招聘属性">{{ item.value1 == '0' ? '社会招聘' : '校园招聘' }}</el-descriptions-item>
          </el-descriptions>
        </div>
        <div v-if="expanded  && currentIndex == index" class="details">
          <div class="back9_2">
            <div>岗位职责</div>
            <div v-html="item.details"></div>
          </div>

          <!-- 岗位职责内容 -->
          <div class="back9_2">
            <div>任职资格</div>
            <div v-html="item.remark"></div>
          </div>
          <!-- 任职资格内容 -->
        </div>
      </div>
      <!--      <el-table-->
      <!--        :data="tableData"-->
      <!--        border-->
      <!--        stripe-->
      <!--        style="min-height: 0 !important; width: 100%"-->
      <!--      >-->
      <!--        <el-table-column label="岗位名称" prop="jobTitle"></el-table-column>-->
      <!--        <el-table-column label="事业群" prop="careerGroup"></el-table-column>-->
      <!--        <el-table-column label="工作地址" prop="workAddress"></el-table-column>-->
      <!--        <el-table-column label="招聘人数" prop="numberPeople"></el-table-column>-->
      <!--        <el-table-column type="expand" width="90px" label="查看详情">-->
      <!--          <template slot-scope="props">-->
      <!--            <el-form label-position="left" inline class="demo-table-expand">-->
      <!--              <el-form-item label="岗位职责：">-->
      <!--                <div v-html="props.row.details"></div>-->
      <!--              </el-form-item>-->
      <!--              <el-form-item label="任职要求：">-->
      <!--                <span v-html="props.row.remark"></span>-->
      <!--              </el-form-item>-->
      <!--              <el-form-item label="联系方式：">-->
      <!--                <span>{{ props.row.value2 }}</span-->
      <!--                >&nbsp;&nbsp;-->
      <!--                <span>{{ props.row.value3 }}</span>-->
      <!--              </el-form-item>-->
      <!--            </el-form>-->
      <!--          </template>-->
      <!--        </el-table-column>-->
      <!--      </el-table>-->
    </div>
    <!-- 分页组件 -->
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[6, 10, 15, 100]"
      :page-size="pageSize"
      :total="total"
      layout="total, prev, pager, next, jumper">
    </el-pagination>
  </div>
</template>


<script>
import axios from "axios";
//   import '@/assets/css/my_breadcrumb.css';
export default {
  data() {
    return {
      caseList: [],
      tableData: [],
      showBack9: false,
      expanded: false,
      currentIndex: null,
      total: 0,
      currentPage: 1,
      pageSize: 6, // 每页显示条数
    };
  },
  created() {

  this.getList(1)
    // axios.post('http://116.205.170.48:8081/api/ago/talentsList')
    //     .then(response => {
    //       this.tableData = response.data.rows;
    //     })
    //     .catch(error => {
    //       console.error('There was an error!', error);
    //     });
  },
  computed: {},
  mounted() {
    this.showTransition()
  },
  methods: {
    // 页面大小改变时触发
    handleSizeChange(val) {
      this.pageSize = val;
      // 重新加载数据
      this.getList(val)
    },
    // 当前页改变时触发
    handleCurrentChange(val) {
      this.currentPage = val;
      // 重新加载数据
      this.getList(val)
    },
    getList(num1){
      this.$http.post("honorsList").then((response) => {
        // console.log(response.data.rows);
        this.caseList = response.data.rows;
      });
      this.$http.post("talentsList?pageNum=" + num1 + "&pageSize=" + this.pageSize + "").then((response) => {
        this.tableData = response.data.rows;
        this.total = response.data.total;
      });

    },
    toggleExpand(index) {
      this.currentIndex = index
      this.expanded = !this.expanded;
    },
    showTransition() {
      // 创建一个IntersectionObserver实例
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          // 检查当前entry（元素）是否至少50%可见
          if (entry.isIntersecting) {
            // 如果是，执行特定操作，例如：
            if (entry.target.id == 'back9Id') {
              this.showBack9 = true
            }
            // 你可以在这里设置特定于该元素的属性，比如：
            // this.someSpecificProperty[entry.target.id] = true;

            // 如果只需要在元素第一次变为50%可见时触发一次，可以停止观察该元素
            this.observer.unobserve(entry.target);
          }
        });
      }, {
        root: null, // 观察相对于视口
        threshold: 0.5 // 当元素50%可见时触发
      });

      // 假设你有一个包含所有需要观察的元素ID的数组
      const elementIds = ['back9Id']; // 添加更多ID

      // 遍历数组，为每个元素设置观察
      elementIds.forEach(id => {
        const element = document.getElementById(id);
        if (element) {
          this.observer.observe(element);
        }
      });
    },
  }
};
</script>


<style scoped lang="scss">
.el-pagination {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
::v-deep .el-descriptions {
  background-color: #eff9ff;
}

::v-deep .el-descriptions__body {
  background-color: #eff9ff;
}

.back9_3 {
  cursor: pointer;
  margin-bottom: 20px;
  padding: 0 135px 0 50px;
}

.back9_4 {
  padding: 10px 85px;
}

.back9_2 {
  margin: 20px 0;
  padding: 0 85px;
}

.back9_5 {
  width: fit-content;
  margin: 20px 0;
  cursor: pointer;
}

.back9_1 {
  font-size: 35px;
  font-weight: bold;
  padding: 0 0px 20px 135px;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 2s ease;
}

.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */
{
  transform: translateX(-20%);
  opacity: 0;
}

.slide-fade-enter-to, .slide-fade-leave {
  transform: translateX(0);
  opacity: 1;
}

.RecruitingTalents {
  margin-top: 0px;
padding: 30px 0;
  .el-breadcrumb {
    line-height: normal !important;
    width: 100%;
    font-size: 1.25rem;
    padding: 1.25rem 3.4rem;
    border-bottom: 1px solid #928e8e;
    display: flex;
    margin-left: 0;
  }

  .my_div1 {
    height: auto;
    //margin-top: 90px;
    width: 100%;
  }
}

::v-deep.el-breadcrumb > span {
  display: flex;
}

::v-deep.el-breadcrumb > span > i {
  padding: 1px 0 0 0;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 100%;
}

// .demo-table-expand {
//   font-size: 0;
// }

// .demo-table-expand label {
//   width: 20px;
//   color: #99a9bf;
//   font-size:17px !important;
// }

// .demo-table-expand .el-form-item {
//   margin-right: 0;
//   margin-bottom: 0;
//   width: 50%;
// }

// .aaaaaaaa{
//   font-size: 17px;
// }
</style>
