<template>
  <div class="ContactUs">
    <!--    <el-breadcrumb separator-class="el-icon-arrow-right my_el-breadcrumb">-->
    <!--&lt;!&ndash;      <el-breadcrumb-item>首页</el-breadcrumb-item>&ndash;&gt;-->
    <!--      <el-breadcrumb-item>联系我们</el-breadcrumb-item>-->
    <!--    </el-breadcrumb>-->

    <div id="back10Id">
      <transition name="slide-fade">
        <div v-if="showBack10" class="back10_1">联系我们丨Contact US</div>
      </transition>
    </div>
    <div class="my_div1 my_el_row">
      <el-row :gutter="20" style="height: 100%">
        <el-col :span="16" style="height: 100%">
          <div class="grid-content">
            <div class="back10_2">
              <div>
                <div class="back10_3">{{ caseList.companyName }}</div>
                <!--            <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
              </div>
              <div class="text ">
                <i class="el-icon-user"></i>&nbsp;&nbsp;业务：{{
                  caseList.name
                }}
              </div>
              <div class="text ">
                <i class="el-icon-phone-outline"></i>&nbsp;&nbsp;电话：{{
                  caseList.phone
                }}
              </div>
              <div class="text ">
                <i class="el-icon-message"></i>&nbsp;&nbsp;邮箱：{{
                  caseList.email
                }}
              </div>
              <div class="text ">
                <i class="el-icon-location-outline"></i>&nbsp;&nbsp;地址：{{
                  caseList.address
                }}
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content">
            <div class="map-container">
              <el-amap id="container"></el-amap>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="my_div1 my_el_row" style="margin-top: 50px;">
      <el-row :gutter="20" style="height: 100%">
        <el-col :span="16" style="height: 100%">
          <div class="grid-content">
            <div class="back10_2">
              <div>
                <div class="back10_3">{{ caseList.nanchanCompany }}</div>
                <!--            <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
              </div>
              <div class="text ">
                <i class="el-icon-user"></i>&nbsp;&nbsp;业务：{{
                  caseList.nanChanName
                }}
              </div>
              <div class="text ">
                <i class="el-icon-phone-outline"></i>&nbsp;&nbsp;电话：{{
                  caseList.nanChanPhone
                }}
              </div>
              <div class="text ">
                <i class="el-icon-message"></i>&nbsp;&nbsp;邮箱：{{
                  caseList.nanChanEmail
                }}
              </div>
              <div class="text ">
                <i class="el-icon-location-outline"></i>&nbsp;&nbsp;地址：{{
                  caseList.nanChanAddress
                }}
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content">
            <div class="map-container">
              <el-amap id="container2"></el-amap>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
// import '@/assets/css/my_breadcrumb.css';
import {AMapManager, lazyAMapApiLoaderInstance} from 'vue-amap'

const amapManager = new AMapManager()
export default {
  // name: 'amap',
  // props: {
  //   center: {
  //     type: Array,
  //     default: () => [116.39732, 39.917797] // 西湖风景区的经纬度
  //   },+
  // },
  data() {
    const self = this
    return {
      currentDate: new Date(),
      caseList: {},
      plugin: {
        showMaker: true
      },
      markers: [],
      showBack10: false,
    }
  },
  created() {
    this.$http.post('gitContactUs').then((response) => {
      this.caseList = response.data.data
    })
    lazyAMapApiLoaderInstance.load().then(() => {
      this.initMap()
    })
  },
  computed: {},
  mounted() {
    this.showTransition()
  },
  methods: {

    showTransition() {
      // 创建一个IntersectionObserver实例
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          // 检查当前entry（元素）是否至少50%可见
          if (entry.isIntersecting) {
            // 如果是，执行特定操作，例如：
            if (entry.target.id == 'back10Id') {
              this.showBack10 = true
            }
            // 你可以在这里设置特定于该元素的属性，比如：
            // this.someSpecificProperty[entry.target.id] = true;

            // 如果只需要在元素第一次变为50%可见时触发一次，可以停止观察该元素
            this.observer.unobserve(entry.target);
          }
        });
      }, {
        root: null, // 观察相对于视口
        threshold: 0.5 // 当元素50%可见时触发
      });

      // 假设你有一个包含所有需要观察的元素ID的数组
      const elementIds = ['back10Id']; // 添加更多ID

      // 遍历数组，为每个元素设置观察
      elementIds.forEach(id => {
        const element = document.getElementById(id);
        if (element) {
          this.observer.observe(element);
        }
      });
    },
    // 给地图添加标记
    initMap() {
      this.map = new AMap.Map('container', {
        center: [106.559961, 29.534752],
        pitch: 50, // 俯仰角度
        viewMode: '2D', // 使用3D视图
        zoom: 16, // 级别
        zoomControl: true,
      })
      const marker = new AMap.Marker({
        position: new AMap.LngLat(106.559961, 29.534752), // 标记位置
        map: this.map // 指定将标记添加到哪个地图实例中
      })
      marker.setMap(this.map)


      this.map2 = new AMap.Map('container2', {
        center: [115.86,28.69],
        pitch: 50, // 俯仰角度
        viewMode: '2D', // 使用3D视图
        zoom: 16, // 级别
        zoomControl: true,
      })
      const marker2 = new AMap.Marker({
        position: new AMap.LngLat(115.86,28.69), // 标记位置
        map: this.map2 // 指定将标记添加到哪个地图实例中
      })
      marker2.setMap(this.map2)
    },
    toSub(item) {
      this.$router.push({path: '/CompanyNewsLevel', query: {item: item}})
    }
  }
}
</script>

<style scoped lang="scss">
.back10_3 {
  font-size: 35px;
  font-weight: 600;
}
.back10_2 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.back10_1 {
  font-size: 35px;
  font-weight: bold;
  padding: 0 135px;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 2s ease;
}

.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */
{
  transform: translateX(-20%);
  opacity: 0;
}

.slide-fade-enter-to, .slide-fade-leave {
  transform: translateX(0);
  opacity: 1;
}

.map-container {
  border-radius: 10px; /* 调整这个值来改变圆角的程度 */
  overflow: hidden; /* 确保地图内容不会超出圆角边界 */
  height: 250px;
}

.map {
  height: 100%;
}

.ContactUs {
  padding: 20px 0;
  margin-top: 0px;
  .el-breadcrumb {
    line-height: normal !important;
    text-align: center;
    width: 100%;
    font-size: 1.25rem;
    padding: 1.25rem 3.4rem;
    border-bottom: 1px solid #928e8e;
    display: flex;
    margin-left: 0;
  }

  .my_div1 {
    padding: 0 135px;
    height: auto;
    margin-top: 10px;
    //margin-top: 90px;
    width: 100%;
  }

  .my_el_row {
    height: 250px;
    // margin-left: 6%;
  }
}

::v-deep.el-breadcrumb > span {
  display: flex;
}

::v-deep.el-breadcrumb > span > i {
  padding: 1px 0 0 0;
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
  height: 500px;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  height: 100%;
  border-radius: 4px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.text {
  font-size: 22px;
  //margin-left: -1000px;
  text-align: left;
}

.item {
 //margin-bottom: 10px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.box-card {
  width: 480px;
}
</style>
