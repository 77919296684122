  <template>
  <div class="companyNews">
    <el-breadcrumb separator-class="el-icon-arrow-right my_el-breadcrumb">
<!--      <el-breadcrumb-item>首页</el-breadcrumb-item>-->
<!--      <el-breadcrumb-item>新闻动态</el-breadcrumb-item>-->
      <el-breadcrumb-item>详情内容</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="my_div1">
      <!-- 使用 router-link 组件包裹 i 标签实现跳转 -->
      <!--      <router-link to="/CompanyNews">-->
      <!--        <i style="font-size: 40px;float: right;display: flex;margin-right: 200px" class="el-icon-close my_span"></i>-->
      <!--      </router-link>-->
        <h3 style="text-align: center;margin-bottom: 1rem">{{ caseList.newsTitle }}</h3>
      <h4 type="text" style="text-align: center;">{{ caseList.createTime }}</h4>
      <div style="margin-top: 1rem;" v-html="caseList.newsText"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      currentDate: new Date(),
      caseList: {},
      dialogVisible: false,
      typeName: ''
    }
  },
  created() {
  },
  mounted() {
    // this.caseList = this.$route.query.item;
    this.typeName = this.$route.query.type;
    this.caseList = JSON.parse(localStorage.getItem('item') || '[]');

    //   console.log(this.caseList);
  },
  methods: {}
};
</script>


<style scoped lang="scss">
.companyNews {
  margin-top:0px;
  padding: 0 135px 20px 135px;
  .el-breadcrumb {
    line-height: normal !important;
    width: 100%;
    font-size: 1.25rem;
    padding:1.25rem 0;
    border-bottom: 1px solid #928e8e;
    display: flex;
    margin-left: 0;
  }

  .my_div1 {
    width: 100%;
    height: auto;
    margin-top: 2rem;
    //margin-top: 90px;
  }

}
</style>
