<template>
  <div>
    <el-carousel trigger="click" @change="change" :autoplay="true" interval="8000"
                 :style="{ backgroundImage:`url(${require('../assets/img/background.png')})`}" class="div1">
      <el-carousel-item v-for="(item, index) in 3" :key="index" class="div_6">
        <div v-show="currentIndex === 0" class="div_5">
          <div style="display: flex;flex-direction: row;">
            <div class="div17">
              <transition name="slide-fade">
                <div class="div2" v-if="currentIndex === 0">Welcome to baisp</div>
              </transition>
              <transition name="slide-fade2">
                <div v-if="currentIndex === 0">
                  <div class="div3">
                    我们是一家专业为电子电路行业「PCB」提供MES丨ERP并集成<br>
                    CRM、APS、QMS、WMS、SRM多项子系统的智能方案解决商，<br>
                    希望能够为您提供优质的服务。
                  </div>
                </div>
              </transition>

            </div>

            <div class="imgSize_4">
              <transition name="slide-fade19">
                <div v-if="currentIndex === 0" style="display: flex;flex-direction: column">
                  <div>
                    <img class="imgSize_3" src="@/assets/img/img-2.png">
                  </div>
                  <div class="div14">
                    <span>PCB数智化方案践行者。</span>
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <div   class="btnSty">
            <transition name="slide-fade3">
              <div v-if="currentIndex === 0">
                <el-button class="btnSize" @click="toMore()">
                  了解更多
                </el-button>
              </div>
            </transition>
            <transition name="slide-fade19">

            </transition>
          </div>

        </div>
        <div v-show="currentIndex === 1" class="paddingSty">
          <transition name="slide-fade5">
            <div class="div5" v-if="currentIndex === 1">
              <img src="@/assets/img/mes.png" class="div10">
              <img src="@/assets/svg/baisp.svg" class="div10">
            </div>
          </transition>
          <transition name="slide-fade6">
            <div class="div6" v-if="currentIndex === 1">
              <img src="@/assets/img/erp.png" class="div10">
              <img src="@/assets/svg/baisp.svg" class="div10">
            </div>
          </transition>
          <transition name="slide-fade7">
            <div class="div7" v-if="currentIndex === 1">
              <img src="@/assets/img/wms.png" class="div10">
              <img src="@/assets/svg/baisp.svg" class="div10">
            </div>
          </transition>
          <transition name="slide-fade8">
            <div class="div8" v-if="currentIndex === 1">
              <img src="@/assets/img/qms.png" class="div10">
              <img src="@/assets/svg/baisp.svg" class="div10">
            </div>
          </transition>
          <transition name="slide-fade9">
            <div class="div9" v-if="currentIndex === 1">
              <img src="@/assets/img/aps.png" class="div10">
              <img src="@/assets/svg/baisp.svg" class="div10">
            </div>
          </transition>
          <transition name="slide-fade10">
            <div class="div11" v-if="currentIndex === 1">
              <img src="@/assets/img/crm.png" class="div10">
              <img src="@/assets/svg/baisp.svg" class="div10">
            </div>
          </transition>
          <transition name="slide-fade11">
            <div class="div12" v-if="currentIndex === 1">
              <img src="@/assets/img/srm.png" class="div10">
              <img src="@/assets/svg/baisp.svg" class="div10">
            </div>
          </transition>
          <transition name="slide-fade16">
            <div class="div4" v-if="currentIndex === 1">
              <img class="imgSize_2" v-if="currentIndex === 1" src="@/assets/svg/data background.svg"/>
              <div class="div15">
                数据一目了然，尽收眼中。
              </div>
            </div>
          </transition>
        </div>
        <div v-show="currentIndex === 2">
          <div class="div_4">
            <transition name="slide-fade13">
              <div v-if="currentIndex === 2">
                <img class="div_7" src="@/assets/img/img2.png"/>
              </div>
            </transition>
            <transition name="slide-fade18">
              <div class="div16" v-if="currentIndex === 2">
                <div class="div_1">信息化丨数字化丨智能化</div>
                <div class="div_2">未来无限可能、管理更集成、更智能。</div>
              </div>
            </transition>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div id="back2Id" :style="{ backgroundImage:`url(${require('../assets/img/back2.png')})`}" class="back2"
         style=" overflow: hidden;">
      <transition name="slide-fade18">
        <div v-if="show" class="back2_10">
          <div class="back2_1">产品版本丨Product Version</div>
          <div class="back2_9">
            <div class="back2_11">
              <img class="back2_7" src="@/assets/img/pruductVersion_3.png"/>
            </div>
            <div class="back2_4">
              <div class="back2_2">ERP丨企业资源计划</div>
              <div class="back2_3">系统化的管理，决策得心应手。</div>
            </div>
          </div>
          <div class="back2_9">
            <div class="back2_11">
              <img class="back2_7" src="@/assets/img/productVersion_1.png"/>
            </div>
            <div class="back2_4">
              <div class="back2_2">MES丨EAP丨PLC丨AGV</div>
              <div class="back2_3">制造过程信息实时监控。</div>
            </div>
          </div>
          <div class="back2_9">
            <div class="back2_11">
              <img class="back2_7" src="@/assets/img/productVersion_2.png"/>
            </div>
            <div class="back2_4">
              <div class="back2_2">CRM丨APS丨QMS丨WMS丨SRM</div>
              <div class="back2_3">集成多项子系统，流程、数据、权限层次分明。</div>
            </div>

          </div>
        </div>
      </transition>
    </div>

    <!--    首页解决方案-->
    <!--    <div :style="{ backgroundImage:`url(${require('../assets/img/back3.png')})`}" class="back3">-->
    <!--      <div class="back3_1">解决方案丨Solution</div>-->
    <!--      <div class="back3_2">-->
    <!--        <div  @click="content(1)" :class="type == 1 ? 'isActive':'noActive'">汽车行业</div>-->
    <!--        <div  @click="content(2)" :class="type == 2 ? 'isActive':'noActive'">电子行业</div>-->
    <!--        <div  @click="content(3)" :class="type == 3 ? 'isActive':'noActive'">PCB行业</div>-->
    <!--      </div>-->
    <!--      <transition name="slide-fade17">-->
    <!--        <div v-show="type == 1" class="back3_3">-->
    <!--&lt;!&ndash;          <div class="back3_4">标题</div>&ndash;&gt;-->
    <!--          <div v-for="(list,index) in caseList" :key="index">-->
    <!--            &lt;!&ndash;      <h2 style="text-align: center;">{{ list.title }}</h2>&ndash;&gt;-->
    <!--            <div style="user-select: none" v-html="list.value1"></div>-->
    <!--          </div>-->
    <!--          <div class="car_sty" >-->
    <!--            <img src="@/assets/img/car_1.png" class="carSize">-->
    <!--            <img src="@/assets/img/car_2.png" class="carSize">-->
    <!--            <img src="@/assets/img/car_3.png" class="carSize">-->
    <!--            <img src="@/assets/img/car_4.png" class="carSize">-->
    <!--            <img src="@/assets/img/car_5.png" class="carSize">-->
    <!--            <img src="@/assets/img/car_6.png" class="carSize6">-->
    <!--          </div>-->
    <!--          <div>-->
    <!--            <el-button  type="primary">免费试用</el-button>-->
    <!--          </div>-->
    <!--        </div>-->

    <!--      </transition>-->
    <!--      <transition name="slide-fade17">-->
    <!--        <div v-show="type == 2" class="back3_3">-->
    <!--&lt;!&ndash;          <H2>标题</H2>&ndash;&gt;-->
    <!--          <div v-for="(list,index) in electricList" :key="index">-->
    <!--            &lt;!&ndash;      <h2 style="text-align: center;">{{ list.title }}</h2>&ndash;&gt;-->
    <!--            <div style="user-select: none" v-html="list.value1"></div>-->
    <!--          </div>-->
    <!--          <div class="car_sty" >-->
    <!--            <img src="@/assets/img/elec_1.png" class="carSize">-->
    <!--            <img src="@/assets/img/elec_2.png" class="carSize">-->
    <!--            <img src="@/assets/img/elec_3.png" class="carSize">-->
    <!--            <img src="@/assets/img/elec_4.png" class="carSize">-->
    <!--            <img src="@/assets/img/elec_5.png" class="carSize">-->
    <!--            <img src="@/assets/img/elec_6.png" class="carSize">-->
    <!--          </div>-->
    <!--          <div>-->
    <!--            <el-button  type="primary">免费试用</el-button>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </transition>-->
    <!--      <transition name="slide-fade17">-->
    <!--          <div v-show="type == 3" class="back3_3">-->
    <!--&lt;!&ndash;          <H2>标题</H2>&ndash;&gt;-->
    <!--            <div v-for="(list,index) in electricList" :key="index">-->
    <!--              &lt;!&ndash;      <h2 style="text-align: center;">{{ list.title }}</h2>&ndash;&gt;-->
    <!--              <div style="user-select: none" v-html="list.value2"></div>-->
    <!--            </div>-->
    <!--          <div class="car_sty" >-->
    <!--            <img src="@/assets/img/pcb_1.png" class="carSize">-->
    <!--            <img src="@/assets/img/pcb_2.png" class="carSize">-->
    <!--            <img src="@/assets/img/pcb_3.png" class="carSize">-->
    <!--            <img src="@/assets/img/pcb_4.png" class="carSize">-->
    <!--            <img src="@/assets/img/pcb_6.png" class="carSize">-->
    <!--            <img src="@/assets/img/pcb_7.png" class="carSize">-->
    <!--            <img src="@/assets/img/pcb_8.png" class="carSize">-->
    <!--            <img src="@/assets/img/pcb_9.png" class="carSize">-->
    <!--          </div>-->
    <!--          <div>-->
    <!--            <el-button type="primary">免费试用</el-button>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </transition>-->
    <!--    </div>-->

    <div :style="{ backgroundImage:`url(${require('../assets/img/back2.png')})`}" class="back4">
      <div class="scroll-container">
        <div class="scroll-text">
          <div style="display: flex;flex-direction: row;align-items: center">
            <div class="back4_1">策略丨Strategy</div>

            <img src="@/assets/img/car2.png" class="imgSize">
            <img src="@/assets/img/car3.png" class="imgSize">
            <img src="@/assets/img/car4.png" class="imgSize">
<!--            <img src="@/assets/img/car5.png" class="imgSize">-->
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "index2",
  data() {
    return {
      // images: [
      //   require('../assets/baisp1.png'),
      //   require('../assets/baisp2.png'),
      //   require('../assets/baisp1.png'),
      // ],
      show: false,
      currentIndex: null,
      observer: null, // 添加 observer 属性
      type: 1,
      total: 0,
      currentPage: 1,
      pageSize: 1, // 每页显示条数
      caseList: [],
      electricList: []
    };
  },
  created() {
    this.carContent(1)
    this.electricContent(1)
  },
  mounted() {
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.show = true;
          this.observer.unobserve(entry.target); // 一旦触发，停止观察
        }
      });
    }, {
      root: null, // 观察相对于视口
      threshold: 0.5 // 当元素 50% 可见时触发
    });
    this.observer.observe(document.getElementById('back2Id'));// 选择要观察的元素

    // 页面加载后，设置一个短暂的延时，然后设置 currentIndex 为 0
    setTimeout(() => {
      this.currentIndex = 0;
    }, 10); // 100 毫秒的延时，可以根据需要调整
  },
  methods: {
    carContent(num1) {
      this.$http.get("solutionList?pageNum=" + num1 + "&pageSize=" + this.pageSize + "").then((response) => {
        this.caseList = response.data.rows;

      });
    },
    electricContent(num1) {
      this.$http.get("LocalSolutionList?pageNum=" + num1 + "&pageSize=" + this.pageSize + "").then((response) => {
        this.electricList = response.data.rows;
      });
    },
    toMore() {
// 使用Vue Router的push方法跳转到/CompanyIntroduction
      this.$router.push('/CompanyIntroduction');
    },
    change(index) {
      this.currentIndex = index;
    },
    content(type) {
      this.type = type
    }
  }
}
</script>

<style scoped>
.carSize6 {
  height: 130px;

}
.div17 {
  height: 350px;
}
.carSize {
  height: 80px;
}

.car_sty {
  height: 130px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
  margin-top: 20px;
}

.imgSize_4 {
 height: 350px;
  margin-left: 300px;
  align-self: center;
}

.imgSize_3 {
  opacity: 0.3;
  height: 350px;
}

.imgSize_2 {
  opacity: 0.5;
  height: 350px;
}

::v-deep .el-carousel__container {
  height: 100% !important;
}

.imgSize {
  margin-left: 30px;
  width: 100px;
}

.btnSize {
  height: 60px;
  width: 200px;
}

.div_5 {
  padding: 100px 135px;
  display: flex;
  flex-direction: column;
}

.div_4 {
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 100px 0 0 0;
}

.div_3 {
  height: 100%;

}

.paddingSty {
  padding: 0px 135px;
}

.back4_1 {
  font-size: 40px;
  font-weight: bold;
}

.back3_3 {
  padding: 20px 50px;
  margin-top: 40px;
  color: #ffffff;
  min-height: 400px;
  border: #ffffff 1px solid;
  border-radius: 10px;
}

.back3_4 {
  font-size: 35px;
}

.back3_1 {
  /*height: 45px;*/
  font-weight: bold;
  font-size: 35px;
  color: #ffffff;
}

.back3_2 {
  font-weight: bold;
  font-size: 35px;
  margin-top: 40px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.back2_8 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.back2_9 {
  width: 100%;
  height: 205px;
  display: flex;
  padding-left: 100px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.back2_11 {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.back2_7 {
  border: rgba(2, 2, 2, 0.62) 1px solid;
  width: 700px;
  height: 205px;
}

/*.back2_5 {*/
/*  margin-top: 20px;*/
/*  height: 100%;*/
/*  display: flex;*/
/*  flex-direction: row;*/
/*  justify-content: space-between;*/
/*}*/

.back2_6 {
  width: 100%;
  display: flex;

  flex-direction: row;
  gap: 50px
}

.back2_4 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  width: 700px;
}

.back2_2 {
  font-size: 35px;
  font-weight: bold;
}

.back2_3 {
  font-size: 20px;
}

.back2_1 {
  width: 700px;
  align-self: end;
  font-weight: bold;
  font-size: 35px;
  margin-bottom: 0px;
}

.back2 {

  height: 750px;
  padding: 20px 135px 0 135px;
  background-size: 100% 100%; /* 让背景图片覆盖整个div */
  background-position: center; /* 背景图片居中 */
  background-repeat: no-repeat; /* 防止背景图片重复 */
}

.back2_10 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}

.back3 {
  padding: 50px 135px;
  height: 750px; /* 或者其他适合你设计的高度 */
  background-size: 100% 100%; /* 让背景图片覆盖整个div */
  background-position: center; /* 背景图片居中 */
  background-repeat: no-repeat; /* 防止背景图片重复 */
}

.back4 {
  padding: 20px 135px;
  height: 100%; /* 或者其他适合你设计的高度 */
  background-size: 100% 100%; /* 让背景图片覆盖整个div */
  background-position: center; /* 背景图片居中 */
  background-repeat: no-repeat; /* 防止背景图片重复 */
  box-shadow: 0 10px 18px rgba(0, 0, 0, 0.3);

}

.div1 {
  height: 750px; /* 或者其他适合你设计的高度 */
  background-size: 100%; /* 让背景图片覆盖整个div */
  background-position: center; /* 背景图片居中 */
  background-repeat: no-repeat; /* 防止背景图片重复 */
}

.div_6 {
  height: 100%;
}

.div2 {
  width: fit-content;
  font-weight: bold;
  font-size: 52px;
  color: #ffffff;
  padding-bottom: 100px;
}

.div3 {
  width: 120%;
  font-size: 20px;
  color: #ffffff;

}

.div14 {

  height: 60px;
  display: flex;
  align-items: center;
 justify-content: center;
  font-size: 20px;
  color: #ffffff;

}

.div15 {
  font-size: 20px;
  color: #ffffff;
  text-align: center;
}

.div16 {
  margin-right: 400px;
}

.div4 {
  text-align: center;
  color: #ffffff;
  font-size: 17px;
  position: absolute;
  left: 55%;
  top: 10%;
}

.div5 {
  position: absolute;
  left: 155px;
  top: 80px;
  height: 133px;
  width: 132px;
  display: flex;
  align-items: center
}

.div6 {
  position: absolute;
  left: 250px;
  top: 175px;
  height: 133px;
  width: 132px;
  display: flex;
  align-items: center
}

.div7 {
  position: absolute;
  left: 345px;
  top: 270px;
  height: 133px;
  width: 132px;
  display: flex;
  align-items: center
}

.div8 {
  position: absolute;
  left: 440px;
  top: 365px;
  height: 133px;
  width: 132px;
  display: flex;
  align-items: center
}

.div9 {
  position: absolute;
  left: 155px;
  top: 270px;
  height: 133px;
  width: 132px;
  display: flex;
  align-items: center
}

.div11 {
  position: absolute;
  left: 345px;
  top: 80px;
  height: 133px;
  width: 132px;
  display: flex;
  align-items: center
}

.div12 {
  position: absolute;
  left: 440px;
  top: 175px;
  height: 133px;
  width: 132px;
  display: flex;
  align-items: center
}

.div13 {
  margin-top: 1%;
  margin-left: 57%;
}

.div10 {
  position: absolute;
  width: 120px
}

.btnSty {

  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;

}

.div_1 {
  font-weight: bold;
  font-size: 25px;
}

.div_2 {
  font-weight: bold;
  margin-top: 30px;
  font-size: 17px;
}

.div_7 {
  height: 400px;
}

.slide-fade17-enter-active {
  transition: all 1s ease;
}

.slide-fade17-enter, .slide-fade17-leave-to /* .slide-fade-leave-active in <2.1.8 */
{
  transform: translateX(50%);
  opacity: 0;
}

.slide-fade17-enter-to {
  transform: translateX(0);
  opacity: 1;
}

.slide-fade17-leave {
  transform: translateX(0);
  opacity: 0;
}

.slide-fade19-enter-active, .slide-fade19-leave-active {
  transition: all 3s ease;
}

.slide-fade19-enter, .slide-fade19-leave-to /* .slide-fade19-leave-active in <2.1.8 */
{
  transform: translateX(100%);
  overflow-x: hidden;
  opacity: 0;
}

.slide-fade19-enter-to, .slide-fad19e-leave {
  transform: translateX(0);
  opacity: 1;
}

.slide-fade18-enter-active, .slide-fade18-leave-active {
  transition: all 6s ease;
}

.slide-fade18-enter, .slide-fade18-leave-to /* .slide-fade18-leave-active in <2.1.8 */
{
  transform: translateX(100%);
  opacity: 0;
}

.slide-fade18-enter-to, .slide-fade18-leave {
  transform: translateX(0);
  opacity: 1;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 1.5s ease 0.5s;
}

.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */
{
  transform: translateX(-100%);
  opacity: 0;
}

.slide-fade-enter-to, .slide-fade-leave {
  transform: translateX(0);
  opacity: 1;
}

.slide-fade2-enter-active, .slide-fade2-leave-active {
  transition: all 2s ease 0.5s;
}

.slide-fade2-enter, .slide-fade2-leave-to /* .slide-fade2-leave-active in <2.1.8 */
{
  transform: translateX(-100%);
  opacity: 0;
}

.slide-fade2-enter-to, .slide-fade2-leave {
  transform: translateX(0);
  opacity: 1;
}

.slide-fade3-enter-active, .slide-fade3-leave-active {
  transition: all 2.5s ease 0.5s;
}

.slide-fade3-enter, .slide-fade3-leave-to /* .slide-fade3-leave-active in <2.1.8 */
{
  transform: translateX(-100%);
  opacity: 0;
}

.slide-fade3-enter-to, .slide-fade3-leave {
  transform: translateX(0);
  opacity: 1;
}

.slide-fade4-enter-active, .slide-fade4-leave-active {
  transition: all 1s ease;
}

.slide-fade4-enter, .slide-fade4-leave-to /* .slide-fade4-leave-active in <2.1.8 */
{
  transform: translateX(-100%);
  opacity: 0;
}


.slide-fade4-enter-to, .slide-fade4-leave {
  transform: translateX(0);
  opacity: 1;
}

.slide-fade12-enter-active, .slide-fade12-leave-active {
  transition: all 3s ease;
}

.slide-fade12-enter, .slide-fade12-leave-to /* .slide-fade12-leave-active in <2.1.8 */
{
  transform: translateX(-100%);
  opacity: 0;
}

.slide-fade12-enter-to, .slide-fade12-leave {
  transform: translateX(0);
  opacity: 1;
}

.slide-fade5-enter-active, .slide-fade5-leave-active {
  transition: all 5s ease; /* 总时长为3秒+1.5秒=4.5秒 */
}

.slide-fade5-enter, .slide-fade5-leave-to /* .slide-fade5-leave-active in <2.1.8 */
{
  transform: translateX(-100%);
  opacity: 0;
}

.slide-fade5-enter-to {
  opacity: 1;
  animation: slideAndBack5 4.5s ease forwards;
}

.slide-fade5-leave {
  transform: translateX(0);
  opacity: 1;
}

@keyframes slideAndBack5 {
  0% {
    transform: translateX(-100%);
  }
  60% { /* 3秒占总时间的66.67%，即2/3 */
    transform: translateX(100%);
  }
  100% { /* 最后1.5秒回到100%的位置 */
    transform: translateX(0);
  }
}

.slide-fade6-enter-active, .slide-fade6-leave-active {
  transition: all 5.2s ease; /* 总时长为3秒+1.5秒=4.5秒 */
}

.slide-fade6-enter, .slide-fade6-leave-to /* .slide-fade6-leave-active in <2.1.8 */
{
  transform: translateX(-100%);
  opacity: 0;
}

.slide-fade6-enter-to {
  opacity: 1;
  animation: slideAndBack6 5.2s ease forwards;
}

.slide-fade6-leave {
  transform: translateX(0);
  opacity: 1;
}

@keyframes slideAndBack6 {
  0% {
    transform: translateX(-100%);
  }
  50% { /* 3秒占总时间的66.67%，即2/3 */
    transform: translateX(100%);
  }
  100% { /* 最后1.5秒回到100%的位置 */
    transform: translateX(0);
  }
}


.slide-fade7-enter-active, .slide-fade7-leave-active {
  transition: all 5.4s ease; /* 总时长为3秒+1.5秒=4.5秒 */
}

.slide-fade7-enter, .slide-fade7-leave-to /* .slide-fade7-leave-active in <2.1.8 */
{
  transform: translateX(-100%);
  opacity: 0;
}

.slide-fade7-enter-to {
  opacity: 1;
  animation: slideAndBack7 5.4s ease forwards;
}

.slide-fade7-leave {
  transform: translateX(0);
  opacity: 1;
}

@keyframes slideAndBack7 {
  0% {
    transform: translateX(-100%);
  }
  50% { /* 3秒占总时间的66.67%，即2/3 */
    transform: translateX(100%);
  }
  100% { /* 最后1.5秒回到100%的位置 */
    transform: translateX(0);
  }
}

.slide-fade8-enter-active, .slide-fade8-leave-active {
  transition: all 5.6s ease; /* 总时长为3秒+1.5秒=4.5秒 */
}

.slide-fade8-enter, .slide-fade8-leave-to /* .slide-fade8-leave-active in <2.1.8 */
{
  transform: translateX(-100%);
  opacity: 0;
}

.slide-fade8-enter-to {
  opacity: 1;
  animation: slideAndBack8 5.6s ease forwards;
}

.slide-fade8-leave {
  transform: translateX(0);
  opacity: 1;
}

@keyframes slideAndBack8 {
  0% {
    transform: translateX(-100%);
  }
  50% { /* 3秒占总时间的66.67%，即2/3 */
    transform: translateX(100%);
  }
  100% { /* 最后1.5秒回到100%的位置 */
    transform: translateX(0);
  }
}


.slide-fade9-enter-active, .slide-fade9-leave-active {
  transition: all 5.8s ease; /* 总时长为3秒+1.5秒=4.5秒 */
}

.slide-fade9-enter, .slide-fade9-leave-to /* .slide-fade9-leave-active in <2.1.8 */
{
  transform: translateX(-100%);
  opacity: 0;
}

.slide-fade9-enter-to {
  opacity: 1;
  animation: slideAndBack9 5.8s ease forwards;
}

.slide-fade9-leave {
  transform: translateX(0);
  opacity: 1;
}

@keyframes slideAndBack9 {
  0% {
    transform: translateX(-100%);
  }
  50% { /* 3秒占总时间的66.67%，即2/3 */
    transform: translateX(100%);
  }
  100% { /* 最后1.5秒回到100%的位置 */
    transform: translateX(0);
  }
}

.slide-fade10-enter-active, .slide-fade10-leave-active {
  transition: all 5s ease; /* 总时长为3秒+1.5秒=4.5秒 */
}

.slide-fade10-enter, .slide-fade10-leave-to /* .slide-fade10-leave-active in <2.1.8 */
{
  transform: translateX(-100%);
  opacity: 0;
}

.slide-fade10-enter-to {
  opacity: 1;
  animation: slideAndBack10 5s ease forwards;
}

.slide-fade10-leave {
  transform: translateX(0);
  opacity: 1;
}

@keyframes slideAndBack10 {
  0% {
    transform: translateX(-100%);
  }
  55% { /* 3秒占总时间的66.67%，即2/3 */
    transform: translateX(100%);
  }
  100% { /* 最后1.5秒回到100%的位置 */
    transform: translateX(0);
  }
}

.slide-fade11-enter-active, .slide-fade11-leave-active {
  transition: all 5s ease; /* 总时长为3秒+1.5秒=4.5秒 */
}

.slide-fade11-enter, .slide-fade11-leave-to /* .slide-fade11-leave-active in <2.1.8 */
{
  transform: translateX(-100%);
  opacity: 0;
}

.slide-fade11-enter-to {
  opacity: 1;
  animation: slideAndBack11 5s ease forwards;
}

.slide-fade11-leave {
  transform: translateX(0);
  opacity: 1;
}

@keyframes slideAndBack11 {
  0% {
    transform: translateX(-100%);
  }
  50% { /* 3秒占总时间的66.67%，即2/3 */
    transform: translateX(100%);
  }
  100% { /* 最后1.5秒回到100%的位置 */
    transform: translateX(0);
  }
}

.slide-fade13-enter-active, .slide-fade13-leave-active {
  transition: all 5s ease; /* 总时长为3秒+1.5秒=4.5秒 */
}

.slide-fade13-enter, .slide-fade13-leave-to /* .slide-fade13-leave-active in <2.1.8 */
{
  transform: translateX(-100%);
  opacity: 0;
}

.slide-fade13-enter-to {
  opacity: 1;
  animation: slideAndBack13 5s ease forwards;
}

.slide-fade13-leave {
  transform: translateX(0);
  opacity: 1;
}

@keyframes slideAndBack13 {
  0% {
    transform: translateX(-100%);
  }
  50% { /* 3秒占总时间的66.67%，即2/3 */
    transform: translateX(20%);
  }
  100% { /* 最后1.5秒回到100%的位置 */
    transform: translateX(0);
  }
}


.slide-fade14-enter-active, .slide-fade14-leave-active {
  transition: all 5s ease; /* 总时长为3秒+1.5秒=4.5秒 */
}

.slide-fade14-enter, .slide-fade14-leave-to /* .slide-fade14-leave-active in <2.1.8 */
{
  transform: translateX(100%);
  opacity: 0;
}

.slide-fade14-enter-to {
  opacity: 1;
  animation: slideAndBack14 5s ease forwards;
}

.slide-fade14-leave {
  transform: translateX(0);
  opacity: 1;
}

@keyframes slideAndBack14 {
  0% {
    transform: translateX(100%);
  }
  50% { /* 3秒占总时间的66.67%，即2/3 */
    transform: translateX(-50%);
  }
  100% { /* 最后1.5秒回到100%的位置 */
    transform: translateX(0);
  }
}


.slide-fade15-enter-active, .slide-fade15-leave-active {
  transition: all 5s ease; /* 总时长为3秒+1.5秒=4.5秒 */
}

.slide-fade15-enter, .slide-fade15-leave-to /* .slide-fade15-leave-active in <2.1.8 */
{
  transform: translateX(-20%);
  opacity: 0;
}

.slide-fade15-enter-to {
  opacity: 1;
  animation: slideAndBack15 5s ease forwards;
}

.slide-fade15-leave {
  transform: translateX(0);
  opacity: 1;
}

@keyframes slideAndBack15 {
  0% {
    transform: translateX(-20%);
  }
  50% { /* 3秒占总时间的66.67%，即2/3 */
    transform: translateX(10%);
  }
  100% { /* 最后1.5秒回到100%的位置 */
    transform: translateX(0);
  }
}

.slide-fade16-enter-active, .slide-fade16-leave-active {
  transition: all 5s ease; /* 总时长为3秒+1.5秒=4.5秒 */
}

.slide-fade16-enter, .slide-fade16-leave-to /* .slide-fade16-leave-active in <2.1.8 */
{
  transform: translateX(20%);
  opacity: 0;
}

.slide-fade16-enter-to {
  opacity: 1;
  animation: slideAndBack16 5s ease forwards;
}

.slide-fade16-leave {
  transform: translateX(0);
  opacity: 1;
}

@keyframes slideAndBack16 {
  0% {
    transform: translateX(20%);
  }
  50% { /* 3秒占总时间的66.67%，即2/3 */
    transform: translateX(-10%);
  }
  100% { /* 最后1.5秒回到100%的位置 */
    transform: translateX(0);
  }
}

.isActive {
  padding: 0 50px;
  cursor: pointer;
  color: #1890ff;
  border: #1890ff 1px solid;
  border-radius: 10px;
}

.noActive {
  padding: 0 50px;
  cursor: pointer;
  border: #ffffff 1px solid;
  border-radius: 10px;
}

.scroll-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.scroll-text {
  display: inline-block;
  padding-left: 100%;
  animation: scrollText linear 10s infinite;
}

@keyframes scrollText {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}


@media (max-width: 576px) {
  .div1 {
    height: 27vh; /* 或者其他适合你设计的高度 */
    background-size: 100%; /* 让背景图片覆盖整个div */
    background-position: center; /* 背景图片居中 */
    background-repeat: no-repeat; /* 防止背景图片重复 */
  }

  .back2 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 27vh;
    padding: 50px 135px;
    background-size: 100%; /* 让背景图片覆盖整个div */
    background-position: center; /* 背景图片居中 */
    background-repeat: no-repeat; /* 防止背景图片重复 */
  }

  .back3 {
    padding: 50px 135px;
    min-height: 27vh; /* 或者其他适合你设计的高度 */
    background-size: 100%; /* 让背景图片覆盖整个div */
    background-position: center; /* 背景图片居中 */
    background-repeat: no-repeat; /* 防止背景图片重复 */
  }

  .back4 {
    background-color: #409EFF;
    padding: 50px 135px;
    height: 7vh; /* 或者其他适合你设计的高度 */
    display: flex;
    align-items: center;
    background-size: 100%; /* 让背景图片覆盖整个div */
    background-position: center; /* 背景图片居中 */
    background-repeat: no-repeat; /* 防止背景图片重复 */
    box-shadow: 0 10px 18px rgba(0, 0, 0, 0.3);
  }
}
</style>
