<template>
  <div class="TeamIntroductionLevel my_breadcrumb_1" className="TeamIntroductionLevel ">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!--        <el-breadcrumb-item>首页</el-breadcrumb-item>-->
      <!--        <el-breadcrumb-item>关于我们</el-breadcrumb-item>-->
      <el-breadcrumb-item>介绍详情</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="div1">
      <div>
        <img :src="caseList.photo" class="image">
      </div>
      <div class="div2">
        <h1>{{ caseList.name }}</h1>
        <div class="div3" v-html="caseList.introduce"></div>
      </div>
    </div>
  </div>

</template>


<script>

import axios from "axios";
//   import '@/assets/css/my_breadcrumb.css';
export default {
  data() {
    return {
      currentDate: new Date(),
      caseList: [],
      dialogVisible: false,
    }
  },
  created() {
    // axios.post('http://116.205.170.48:8081/api/ago/introductionList')
    //     .then(response => {
    //       this.caseList = response.data.rows;
    //       // console.log(this.caseList)
    //       // this.loading = false;
    //       // this.userData = response.data;
    //     })
    //     .catch(error => {
    //       console.error('There was an error!', error);
    //     });
  },
  mounted() {
    // console.log(this.$route.query.item);

    // this.caseList=this.$route.query.item
    this.caseList = JSON.parse(localStorage.getItem('item') || '[]');

    // console.log(this.caseList);
  },
  methods: {}
};
</script>


<style scoped lang="scss">
.TeamIntroductionLevel {
  margin-top: 0px;
  padding: 0px 135px 20px 135px;

  .el-breadcrumb {
    line-height: normal !important;
    width: 100%;
    font-size: 1.25rem;
    padding-bottom: 1.25rem;
    padding: 1.25rem 0;
    border-bottom: 1px solid #928e8e;
    display: flex;
    margin-left: 0;
  }

  .my_div1 {
    width: 100%;
    padding-top: 1.875rem;
    display: -ms-flexbox;
    display: flex;
    /*height: 114px;*/
    min-height: 40rem;
  }
}

.div3 {
  margin-top: 30px;
  font-size: 20px;
}

.div2 {
  margin-left: 40px;
}

.div1 {
  display: flex;
  flex-direction: row;
  margin-top: 20px
}

.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  //line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 300px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.my_span {
  cursor: pointer;
  height: 40px;
}

.my_el_row {
  border-radius: 36px;
}
</style>
