import { render, staticRenderFns } from "./CompanyNewsLevel.vue?vue&type=template&id=004b9359&scoped=true"
import script from "./CompanyNewsLevel.vue?vue&type=script&lang=js"
export * from "./CompanyNewsLevel.vue?vue&type=script&lang=js"
import style0 from "./CompanyNewsLevel.vue?vue&type=style&index=0&id=004b9359&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "004b9359",
  null
  
)

export default component.exports