<template>
  <div class="CompanyIntroduction my-menu">
<!--    <div class="paddingSty">-->

<!--      <el-breadcrumb separator-class="el-icon-arrow-right my_el-breadcrumb">-->
<!--        &lt;!&ndash;      <el-breadcrumb-item>首页</el-breadcrumb-item>&ndash;&gt;-->
<!--        &lt;!&ndash;      <el-breadcrumb-item>关于我们</el-breadcrumb-item>&ndash;&gt;-->
<!--        <el-breadcrumb-item>公司介绍</el-breadcrumb-item>-->
<!--      </el-breadcrumb>-->
<!--    </div>-->
    <div  id="companyIntroduction"  :style="{ backgroundImage:`url(${require('../assets/img/back2.png')})`}" class="back6" >
      <transition name="slide-fade15">
        <div v-if="showBack6" class="back5_3">公司介绍 | Introduce</div>
      </transition>
      <div id="back6Id" class="back6_1">
        <transition name="slide-fade15">
          <div v-if="showBack6" class="back6_4" >

            <div
              v-for="(item,index) in caseList " :key="index">
              <div class="back5_7"  v-html="item.title">
              </div>
              <!--        <div class="my_div1_div2" style="background-size: contain"-->
              <!--             v-lazy:background-image="item.picture">-->
              <!--        </div>-->
            </div>
<!--            <div class="back6_5">-->
<!--              <div>-->
<!--                <img src="@/assets/img/back6-3.png" class="imgSize" />-->
<!--                <div class="back6_2">10+</div>-->
<!--                <div class="back6_3">多年行业经验</div>-->
<!--              </div>-->
<!--              <div>-->
<!--                <img src="@/assets/img/back6-2.png" class="imgSize"/>-->
<!--                <div class="back6_2">30+</div>-->
<!--                <div class="back6_3">专业工程师团队</div>-->
<!--              </div>-->
<!--              <div>-->
<!--                <img src="@/assets/img/back6-4.png" class="imgSize"/>-->
<!--                <div class="back6_2">100+</div>-->
<!--                <div class="back6_3">模型</div>-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </transition>

        <transition name="slide-fade16">
          <div v-if="showBack6">
            <img class="imgSize_2" :src="caseList[0].picture"/>
          </div>
        </transition>
      </div>
    </div>

    <div id="companyVision" :style="{ backgroundImage:`url(${require('../assets/img/back5.png')})`}" class="back5" >
      <div class="back5_4">
        <div class="back5_8">
          <img src="@/assets/img/back5-1.png" class="back5_6"/>
        </div>
        <div class="back5_5">
          <div class="back5_1">愿 景丨Vision</div>
          <div>
            <div class="back5_1">愿 景</div>
            <div class="back5_2">成为优秀的智能技术方案解决商。
            </div>
          </div>
          <div>
            <div class="back5_1">使 命</div>
            <div class="back5_2">
              为工业信息化丨数字化丨智能化进步而努力。
            </div>
          </div>
          <div>
            <div class="back5_1">价 值 观</div>
            <div class="back5_2">
              诚信、廉洁、合作、共赢。
            </div>
          </div>
        </div>
      </div>

    </div>
    <div id="teamIntroduction" :style="{ backgroundImage:`url(${require('../assets/img/back2.png')})`}" class="back7" >
      <div id="back7Id">
        <transition name="slide-fade">
          <div v-if="showBack7" class="back7_4">主要成员丨Team</div>
        </transition>
      </div>


      <div style="width: 100%; display: flex;flex-direction: row;justify-content: space-between;">
      <div
             v-for="(item, index) in teamList"
             :key="index">
        <img v-if="item.photo"  :src="item.photo" class="image"/>
        <img v-else  src="@/assets/img/780.jpg" class="image1"/>
        <div v-if="item.name == '张宗浩'">
          <div class="back7_1">张宗浩丨Chuan</div>
          <div class="back7_2">市场总监</div>
        </div>
        <div v-if="item.name == '叶天波'">
          <div class="back7_1">叶天波丨Ian</div>
          <div class="back7_2">运营丨方案经理</div>
        </div>
        <div  v-if="item.name == '陈薇'">
          <div class="back7_1">陈薇丨Alice</div>
          <div class="back7_2">财务审计</div>
        </div>
        <div  v-if="item.name == '汪智宏'">
          <div class="back7_1">汪智宏</div>
          <div class="back7_2">高级软件工程师</div>
        </div>
        <div v-if="item.name == '吴起鹏'">
          <div class="back7_1">吴起鹏丨Wood</div>
          <div class="back7_2">技术经理</div>
        </div>
        <div class="back7_3"  @click="toSub(item)">了解更多</div>
      </div>

      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import menu from "@/utils/menu.css"
export default {
  data() {
    return {

      currentDate: new Date(),
      caseList: {},
      teamList: {},
      showBack6: false,
      showBack7: false,
      observer: null, // 添加 observer 属性
    }
  }, created() {
    this.$http.post('CompanyIntroduceList').then(response => {
      this.caseList = response.data;
    })
    this.$http.post("introductionList").then((response) => {
      this.teamList = response.data.rows;
    });
  },
  computed: {
  },
  watch:{
      // 监视路由变化，执行页面滚动
      $route(to, from) {
        const scrollToTop = to.query.scrollToTop;
        if (scrollToTop) {
          this.$nextTick(() => {
            const element = document.getElementById(scrollToTop);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
          });
        }
      }
  },
  mounted() {
    //首次加载也执行页面滚动
   this.scrollToTop()
   this.showTransition()
  },

  methods: {
    showTransition(){
      // 创建一个IntersectionObserver实例
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          // 检查当前entry（元素）是否至少50%可见
          if (entry.isIntersecting) {
            // 如果是，执行特定操作，例如：
            if (entry.target.id == 'back6Id'){
              this.showBack6=true
            }
            if (entry.target.id == 'back7Id'){
              this.showBack7=true
            }
            // 你可以在这里设置特定于该元素的属性，比如：
            // this.someSpecificProperty[entry.target.id] = true;

            // 如果只需要在元素第一次变为50%可见时触发一次，可以停止观察该元素
            this.observer.unobserve(entry.target);
          }
        });
      }, {
        root: null, // 观察相对于视口
        threshold: 0.5 // 当元素50%可见时触发
      });

      // 假设你有一个包含所有需要观察的元素ID的数组
      const elementIds = ['back6Id', 'back7Id']; // 添加更多ID

      // 遍历数组，为每个元素设置观察
      elementIds.forEach(id => {
        const element = document.getElementById(id);
        if (element) {
          this.observer.observe(element);
        }
      });
    },
    scrollToTop(){
      //首次加载也执行页面滚动
      const scrollToTop = this.$route.query.scrollToTop;
      if (scrollToTop) {
        this.$nextTick(() => {
          const element = document.getElementById(scrollToTop);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        });
      }
    },
    toSub(item) {

      localStorage.setItem('item', JSON.stringify(item));
      // console.log(item)
      this.$router.push({
        path: "/TeamIntroductionLevel",
        // query: {item: item},
      });
    },
  }
};
</script>

<style scoped lang="scss">
.back5_8 {
  height: 100%;
  display: flex;
  align-items: center;
}
.imgSize {
  height: 60px;
}
.imgSize_2 {
 height: 380px
}
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 2s ease;
}

.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */
{
  transform: translateX(-20%);
  opacity: 0;
}

.slide-fade-enter-to, .slide-fade-leave {
  transform: translateX(0);
  opacity: 1;
}

.paddingSty {
  padding: 0 135px;
}
.image {
  height: 400px;
 width: 300px;
  border-radius: 20px;
  background-size: 100% 100% !important;
  background-repeat: no-repeat
}

.image1 {
  height: 400px;
  width: 300px;
  border-radius: 20px;
  background-size: 100% 100% !important;
  background-repeat: no-repeat
}
.back7_4 {
  font-weight: bold;
  font-size: 40px;
  margin: 40px 0;
}
.back7_1 {
  margin-top:10px;
  font-size: 24px;
  font-weight: bold;
}
.back7_2 {
  margin-top: 10px;
  font-size: 16px;
}
.back7_3 {
  cursor: pointer;
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
}
.back7 {
  height: 750px;
  padding: 0px 135px;
  background-size: 100%; /* 让背景图片覆盖整个div */
  background-position: center; /* 背景图片居中 */
  background-repeat: no-repeat; /* 防止背景图片重复 */
}
.back6_5 {
  margin-top:50px;
  display: flex;
  flex-direction: row;
  justify-content: space-around
}

.back6_4 {
  display: flex;
  flex-direction: column;
  width:700px;
}

.back6_2 {
  font-size: 40px;
  font-weight: bold;
}

.back6_3 {
  font-size: 12px;
}
.back5_7 {
  min-height: 380px;
    text-align: justify;
}
.back5_4 {
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 50px 0;
  justify-content: space-around
}

.back5_3 {
  margin-bottom: 20px;
  font-size: 35px;
  font-weight: bold;
  width: 700px;
}

.back5_1 {
  font-size: 40px;
  font-weight: bold;
  color: #ffffff;
}

.back5_2 {
  margin-top: 20px;
  font-size: 15px;
  color: #ffffff;
}

.back5_5 {
  display: flex;
  flex-direction: column;
  gap: 30px
}

.back5_6 {
 height: 400px;
}

.back5 {
  height: 750px;
  padding: 50px 135px;
  background-size: 100% 100%; /* 让背景图片覆盖整个div */
  background-position: center; /* 背景图片居中 */
  background-repeat: no-repeat; /* 防止背景图片重复 */
}

.back6 {
  height: 750px;
  padding: 30px 135px;
  background-size: 100%; /* 让背景图片覆盖整个div */
  background-position: center; /* 背景图片居中 */
  background-repeat: no-repeat; /* 防止背景图片重复 */
}

.back6_1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 380px;
}

.my-menu {
  margin-top: 0px;
  //padding: 0rem 5.3rem;
  .el-breadcrumb {
    line-height: normal !important;
    width: 100%;
    font-size: 1.25rem;
    border-bottom: 1px solid #928e8e;
    display: flex;
    margin-left: 0;


  }

  .my_div1 {
    width: 100%;

    display: -ms-flexbox;
    display: flex;
    /*height: 114px;*/

    margin-left: 0rem;
  }

}

::v-deep.el-breadcrumb > span {
  display: flex;
}

::v-deep.el-breadcrumb > span > i {
  padding: 1px 0 0 0;
}

.my_el_row {
  width: 100%;
}

.my_div1_div1 {
  //border:#000000 solid;
  width: 50%;
  // height: 80%;
  float: left;
  //float: left;
  text-align: justify;
  padding-right: 10px;

  // padding-top: 10px;

  p {
    //font-size:20px;
    font-size: 50px !important;
    text-align: left;
    text-indent: 100px !important;
  }
}

.my_div1_div2 {
  //border: #00ff78 solid;
  width: 50%;
  float: right;
  margin: 0px;
  // background-image: url(../../assets/img/jianjietopmin.jpg);
  background-repeat: no-repeat;

}

.slide-fade15-enter-active, .slide-fade-leave-active {
  transition: all 5s ease; /* 总时长为3秒+1.5秒=4.5秒 */
}

.slide-fade15-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */
{
  transform: translateX(-20%);
  opacity: 0;
}

.slide-fade15-enter-to {
  opacity: 1;
  animation: slideAndBack15 5s ease forwards;
}

.slide-fade15-leave {
  transform: translateX(0);
  opacity: 1;
}

@keyframes slideAndBack15 {
  0% {
    transform: translateX(-20%);
  }
  50% { /* 3秒占总时间的66.67%，即2/3 */
    transform: translateX(10%);
  }
  100% { /* 最后1.5秒回到100%的位置 */
    transform: translateX(0);
  }
}

.slide-fade16-enter-active, .slide-fade-leave-active {
  transition: all 5s ease; /* 总时长为3秒+1.5秒=4.5秒 */
}

.slide-fade16-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */
{
  transform: translateX(20%);
  opacity: 0;
}

.slide-fade16-enter-to {
  opacity: 1;
  animation: slideAndBack16 5s ease forwards;
}

.slide-fade16-leave {
  transform: translateX(0);
  opacity: 1;
}

@keyframes slideAndBack16 {
  0% {
    transform: translateX(20%);
  }
  50% { /* 3秒占总时间的66.67%，即2/3 */
    transform: translateX(-10%);
  }
  100% { /* 最后1.5秒回到100%的位置 */
    transform: translateX(0);
  }
}
@media (max-width: 576px) {
  .back6 {
    height: 27vh;
    padding: 30px 135px;
    background-size: 100%; /* 让背景图片覆盖整个div */
    background-position: center; /* 背景图片居中 */
    background-repeat: no-repeat; /* 防止背景图片重复 */
  }
  .back5 {
    height: 27vh;
    padding: 50px 135px;
    background-size: 100%; /* 让背景图片覆盖整个div */
    background-position: center; /* 背景图片居中 */
    background-repeat: no-repeat; /* 防止背景图片重复 */
  }
  .back7 {
    height: 27vh;
    padding: 0px 135px;
    background-size: 100%; /* 让背景图片覆盖整个div */
    background-position: center; /* 背景图片居中 */
    background-repeat: no-repeat; /* 防止背景图片重复 */
  }
}
</style>
