<template>
  <div class="header2">
    <div class="div1">
      <div style="display: flex;flex-direction: row;align-items: center">
        <H1 class="h1Sty">baisp</H1>
        <div class="div2">
          <div>信息化丨数字化丨智能化</div>
          <div>专业为电子电路行业「PCB」提供MES丨ERP</div>
        </div>
      </div>
    </div>
   <div class="div3">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo my_el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          :router="router"
          text-color="#fff"
          background-color=rgb(214,211,211)
          active-text-color="#000"
          height="400px"
        >
          <el-menu-item index="/" class="div4">首页</el-menu-item>
          <el-submenu  overflow-x:hidden index="1">
            <template slot="title">了解我们</template>
            <el-menu-item overflow-x:hidden index="/CompanyIntroduction" @click="navigateToIntroduction('companyIntroduction','/CompanyIntroduction')"
            >公司介绍
            </el-menu-item>
            <el-menu-item overflow-x:hidden index="/CompanyVision" @click="navigateToIntroduction('companyVision','/CompanyVision')"
            >公司愿景
            </el-menu-item>
            <el-menu-item overflow-x:hidden index="/TeamIntroduction" @click="navigateToIntroduction('teamIntroduction','/TeamIntroduction')"
            >团队介绍
            </el-menu-item
            >
          </el-submenu>
          <el-submenu class="submenu-padding" index="2">
            <template slot="title">解决方案</template>
<!--            <el-menu-item index="/CloudBasedSolutions">汽车行业</el-menu-item>-->
            <el-menu-item index="/LocalPlan">电子电路</el-menu-item>
            <!--          <el-menu-item index="/WMSView">WMS</el-menu-item>-->
            <el-menu-item index="/SmartDevices">智能设备</el-menu-item>
          </el-submenu>
          <el-menu-item index="/CompanyNews" class="div4">手册 | 新闻</el-menu-item>
<!--          <el-submenu class="submenu-padding" index="3">-->
<!--            <template slot="title">手册</template>-->
<!--            <el-menu-item index="/CompanyNews">公司新闻</el-menu-item>-->
<!--            <el-menu-item index="/IndustryNews"> 行业新闻</el-menu-item>-->
<!--          </el-submenu>-->
          <el-menu-item index="/RecruitingTalents" class="div4">加入我们</el-menu-item>
<!--          <el-submenu class="submenu-padding" index="4">-->
<!--            <template slot="title">加入我们</template>-->
<!--            <el-menu-item index="/RecruitingTalents">社会招聘</el-menu-item>-->
<!--            <el-menu-item index="/RecruitingTalentsCampus">-->
<!--              校园招聘-->
<!--            </el-menu-item-->
<!--            >-->
<!--          </el-submenu>-->
          <el-menu-item index="/ContactUs" class="div4">联系我们</el-menu-item>
        </el-menu>
      </div>
  </div>
</template>

<script>
export default {
  name: "header2",
  data() {
    return {
      router: true,
      activeIndex: "/",
    };
  },
  mounted() {
    function isWeChatBrowserOnAndroid() {
      var ua = window.navigator.userAgent.toLowerCase();
      // 检查userAgent是否包含'micromessenger'（微信）和'android'
      return ua.match(/micromessenger/i) ;
    }

// 如果是微信浏览器并且在安卓设备上，修改特定样式
    if (isWeChatBrowserOnAndroid()) {
      var style = document.createElement('style');
      style.innerHTML = `
        .submenu-padding {
            margin:0 30px !important;
        } `;
      document.head.appendChild(style);
    }


  },
  watch:{
    // 监视路由变化，执行页面滚动
    $route(to, from) {
      let str = this.$route.path;
      let str1 = this.$route.query.type;
      this.activeIndex = str
      // if (this.$route.query && this.$route.query.type) {
      //   if (str1 == "公司新闻") {
      //     this.activeIndex = "/CompanyNews";
      //   }
      // } else {
      //   this.activeIndex =
      //     str === "/CompanyNewsLevel"
      //       ? "/CompanyNews"
      //       : str;
      // }
    }
  },
  created() {
    let str = this.$route.path;
    let str1 = this.$route.query.type;
    this.activeIndex = str
    // if (this.$route.query && this.$route.query.type) {
    //   if (str1 == "公司新闻") {
    //     this.activeIndex = "/CompanyNews";
    //   }
    // } else {
    //   this.activeIndex =
    //     str === "/CompanyNewsLevel"
    //       ? "/CompanyNews"
    //       : str;
    // }
  },
  methods: {
    navigateToIntroduction(id,path) {
      this.$router.push({
        path: path,
        query: { scrollToTop: id }
      });
  },
    openExternalSystemInNewWindow() {
      // console.log(this.$apiUrl)
      window.open(this.$apiUrl, "_blank");
    },
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
    },
  },
}
</script>

<style scoped>
.header2 {
  background: black;
  width: 100%;
}
.div4 {
  margin:0px 20px !important;
}
.div1 {
  padding: 20px 50px;
  color: #ffffff;
  font-size: 30px;
}

.div2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: bold;
  border-left: #ffffff 4px solid;
  margin-left:25px;
  padding: 0 25px;
  font-size: 22px
}

.h1Sty {
  font-weight: bold;
  font-size: 52px;
  color: #ffffff;
  margin-left: 85px;
  width: fit-content;
}


.div3 > ul > li {
  /*width: 9.3rem;*/
}

.div3 > ul {
  padding-left: 25px;
  /*flex-direction: row;*/
}

::v-deep .el-menu-item,
::v-deep .el-submenu__title {
  /*padding-left: 1.85rem;*/
  font-size: 18px !important;
  font-weight: 900;
}

.el-menu.el-menu-demo {
  /*padding: 0px 5.3rem;*/
}

::v-deep.el-menu.el-menu--horizontal {
  display: flex;
  align-items: center;
  gap: 40px;
  height: 60px !important;
  border-bottom: none !important;
}

.el-menu--horizontal > ul > li {
  padding-left: 10rem;
  font-size: 18px !important;
  font-weight: 900;
  /*padding-left: 19px !important;*/
  /*overflow-x: hidden;*/
}

.div3 {
  padding: 0 135px 0px 50px;
  text-align: center;
  box-sizing: border-box;
}

.submenu-padding {
 margin: 0;
}

::v-deep .el-menu--horizontal > .el-menu-item.is-active {
  border-bottom-color:  rgba(0, 0, 0, 0.66) !important;
  border-bottom-width: 4px;
}

::v-deep .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom-color: rgba(0, 0, 0, 0.66) !important;
  border-bottom-width: 4px;
}
.el-menu--horizontal>.el-menu-item {
  height: unset !important;
  line-height: unset !important;
}
.el-menu--horizontal>.el-menu-item.is-active {
  background-color: unset !important;
}
::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
  width:72px !important;
  padding: 0 !important;
  margin: 0 20px !important;
  height: unset !important;
  line-height: unset !important;
}
.el-menu--horizontal>.el-menu-item:not(.is-disabled):hover {
  background-color: unset !important;
}
::v-deep .el-menu--horizontal>.el-submenu:hover .el-submenu__title{
  background-color: unset !important;
}

::v-deep .el-menu.el-menu--horizontal {
  border-radius: 3px;
  display: flex;
  align-items: center;
}
.el-dropdown-menu__item, .el-menu-item {
  padding: unset ;
}

@media (max-width: 576px) {
  .header2 {
    background: black;
    width: 100%;
    min-height: 7vh;
  }
}
</style>
