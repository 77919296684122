<template>
  <div class="header">
    <div class="logo">
          <H1 class="div4"
          >&nbsp;您好，欢迎访问佰世普信息技术(重庆)有限公司！我们将及时的为您提供服务。</H1
          >
    </div>
    <div class="UsName1">
      <div class="div_1">
        <div class="div1">
          <div class="div1_p">baisp</div>
        </div>
        <img src="@/assets/logoLine.gif"/>
<!--        <div style="display: flex;align-items: center;justify-content: center">-->
<!--          <div style="border: rgb(0, 255, 255) 0.1rem solid;height: 80%;margin-top: 10px"></div>-->
<!--        </div>-->
        <div id="topDivLeft" class="div_2">
          <div class="font1">专业的ERP|MES系统服务提供商</div>
          <div class="font1">为汽车行业|电子电路行业提供信息技术解决方案
          </div
          >
          <div class="font2">be advantage information solve provider</div>
        </div>
      </div>
      <div class="div_3">
        <img src="../assets/img/phone11.png" style="width: 20%;">
        <div class="div_4">
          <div class="font3">&nbsp;服务热线</div>
          <div class="font3">&nbsp;15202352413</div>
        </div>
      </div>
    </div>

    <div class="div3">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo my_el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        :router="router"
        text-color="#fff"
        background-color=rgb(214,211,211)
        active-text-color="#000"
        height="400px"
      >
        <el-menu-item index="/">首页</el-menu-item>
        <el-submenu class="submenu-padding" overflow-x:hidden index="1">
          <template slot="title">关于我们</template>
          <el-menu-item overflow-x:hidden index="/CompanyIntroduction"
          >公司介绍
          </el-menu-item
          >
          <el-menu-item overflow-x:hidden index="/TeamIntroduction"
          >团队介绍
          </el-menu-item
          >
        </el-submenu>
        <el-submenu class="submenu-padding" index="2">
          <template slot="title">解决方案</template>
          <el-menu-item index="/CloudBasedSolutions">汽车行业</el-menu-item>
          <el-menu-item index="/LocalPlan">电子电路行业</el-menu-item>
          <!--          <el-menu-item index="/WMSView">WMS</el-menu-item>-->
          <el-menu-item index="/SmartDevices">智能设备</el-menu-item>
        </el-submenu>
        <el-submenu class="submenu-padding" index="3">
          <template slot="title">新闻动态</template>
          <el-menu-item index="/CompanyNews">公司新闻</el-menu-item>
          <el-menu-item index="/IndustryNews"> 行业新闻</el-menu-item>
        </el-submenu>
        <el-submenu class="submenu-padding" index="4">
          <template slot="title">招贤纳士</template>
          <el-menu-item index="/RecruitingTalents">社会招聘</el-menu-item>
          <el-menu-item index="/RecruitingTalentsCampus">
            校园招聘
          </el-menu-item
          >
        </el-submenu>
        <el-menu-item index="/ContactUs">联系我们</el-menu-item>
      </el-menu>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      router: true,
      activeIndex: "/",

    };
  },
  mounted() {
  },
  created() {
    let str = this.$route.path;
    let str1 = this.$route.query.type;
    if (this.$route.query && this.$route.query.type) {
      if (str1 == "公司新闻") {
        this.activeIndex = "/CompanyNews";
      }
      if (str1 == "行业新闻") {
        this.activeIndex = "/IndustryNews";
      }
    } else {
      this.activeIndex =
        str === "/CompanyNewsLevel"
          ? "/CompanyNews"
          : str === "/TeamIntroductionLevel"
            ? "/TeamIntroduction"
            : str;
    }
  },
  methods: {

    openExternalSystemInNewWindow() {
      // console.log(this.$apiUrl)
      window.open(this.$apiUrl, "_blank");
    },
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
    },
  },
  beforeDestroy() {
  },
};
</script>

<style scoped lang="scss">
.div4 {
  font-size: 1rem;
  padding: 0 5.3rem;
}

.font3 {
  //font-family: 'Aalon';
  color: #d81e06;
  margin-left: 20px;
  font-size: 1.4rem;;
  font-weight: 600
}

.logo {
  background-color: rgb(238, 238, 238);
  line-height: 3rem;
  display: flex;
  flex-direction: row;
}

.UsName1 {
  justify-content: space-between;
  box-sizing: border-box;
  height: 6.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0 5.3rem 0 5.1rem;
}

.div1_p {
  font-weight: 950;
  font-family: "system-ui";
  font-size: 4.875rem;
  height: 3.75rem;
  color: rgb(0, 255, 255);
}

.div_1 {
  display: flex;
  flex-direction: row
}
.div_2 {
  display: flex;flex-direction: column;justify-content: center;
}
.div_3 {
  display: flex;flex-direction: row;align-items: center;justify-content: flex-start;width: 30rem;
}
.div_4 {
  display: flex;flex-direction: column;gap: 0.6rem
}
.font1 {
  letter-spacing: 0.1rem;
  //font-family: 'Aalon';
  display: flex;
  font-size: 1.4rem;

  font-weight: 600;
}

.font2 {
  letter-spacing: 0.1rem;
 //font-family: 'Aalon';
  margin-top: 0.1rem;
  display: flex;
}

#topDivLeft {
  //display: flex;
  /* margin-left: 0.5rem; */
  /* flex-direction: row; */
  padding-left: 0.5rem;
  padding-top: 0.5rem;
}

.div3 > ul > li {
  width: 9.3rem;
}

.div3 > ul {
  padding-left: 1.85rem;
  //flex-direction: row;
}

::v-deep .el-menu-item,
::v-deep .el-submenu__title {
  //padding-left: 1.85rem;
  font-size: 18px !important;
  font-weight: 900;
}

.el-menu.el-menu-demo {
  padding: 0px 5.3rem;
}

::v-deep.el-menu.el-menu--horizontal {
  border-bottom: none !important;
}

.el-menu--horizontal > ul > li {
  padding-left: 10rem;
  font-size: 18px !important;
  font-weight: 900;
  // padding-left: 19px !important;
  //overflow-x: hidden;
}

.div3 {
  text-align: center;
  box-sizing: border-box;
}

.submenu-padding {
  //padding-left: 20px;
}

</style>
