<template>
  <div id="app">
    <header>
      <header-vue2></header-vue2>
    </header>
    <main>
      <div class="div">
        <router-view/>
      </div>
    </main>
    <footer>
      <div>
        <footer-vue></footer-vue>
      </div>
    </footer>
  </div>
</template>

<script>
import headerVue from "./views/header.vue";
import headerVue2 from "./views/header2.vue";
import footerVue from "./views/footer.vue";

export default {
  name: "app",
  components: {
    headerVue,
    headerVue2,
    footerVue,
  },

  created() {
  },
  mounted() {
    function isWeChatBrowserOnAndroid() {
      var ua = window.navigator.userAgent.toLowerCase();
      // 检查userAgent是否包含'micromessenger'（微信）和'android'
      return ua.match(/micromessenger/i);
    }

// 如果是微信浏览器并且在安卓设备上，修改特定样式
    if (isWeChatBrowserOnAndroid()) {
      var style = document.createElement('style');
      style.innerHTML = `
        .el-menu--popup {
            margin-top: 1rem !important;
            min-width: unset !important;
            width: unset !important;
        } `;
      document.head.appendChild(style);
    }


    //禁止微信浏览器修改字体大小
    // (function() {
    //   if (typeof WeixinJSBridge === 'object' && typeof WeixinJSBridge.invoke === 'function') {
    //     handleFontSize();
    //   } else {
    //     if (document.addEventListener) {
    //       document.addEventListener('WeixinJSBridgeReady', handleFontSize, false);
    //     } else if (document.attachEvent) {
    //       document.attachEvent('WeixinJSBridgeReady', handleFontSize);
    //       document.attachEvent('onWeixinJSBridgeReady', handleFontSize);
    //     }
    //   }
    //   function handleFontSize() {
    //     // 设置网页字体为默认大小
    //     WeixinJSBridge.invoke('setFontSizeCallback', {fontSize: 0});
    //     // 重写设置网页字体大小的事件
    //     WeixinJSBridge.on('menu:setfont', function() {
    //       WeixinJSBridge.invoke('setFontSizeCallback', {fontSize:0});
    //     });
    //   }
    // })();

// this.bodyScale();
// window.addEventListener("resize",this.bodyScale,false);
  },
  methods: {

    // bodyScale(){
    //   var deviceWidth = document.documentElement.clientWidth;
    //   var scale = deviceWidth / 1920;
    //   document.documentElement.style.zoom = scale;
    // }
  },

};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
}

html, body {
  -webkit-text-size-adjust: none; /* 禁止微信、iOS 等设备调整字体大小 */
  -ms-text-size-adjust: none;
  text-size-adjust: none;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.el-menu--popup {
  margin-top: 1rem !important;
  min-width: unset !important;
  width: 135px !important;
}


.el-menu--popup > li {
  //  text-align: center;
  padding-left: 1.2rem !important;
  font-weight: 900;
}

footer {

  background: #ffffff;
  // flex: 1; /* All elements will take equal space */

}

main {
  flex: 1;
  display: flex;
}

// .Myfooter1{
// margin-top: 3rem;
// }

aside {
  width: 200px;
  line-height: 200px;
  background: #f0f0f0;
}

article {
  flex: 1;
  overflow-y: scroll;
  line-height: 1.5;
  background: #f8f8f8;
}

//@media screen and (max-width: 768px) {
//  main {
//    flex-direction: column;
//  }
//
//  aside,
//  article {
//    width: 100%;
//  }
//}

.div {
  width: 100%;
  min-height: 10rem;
}

.Myfooter {
  width: 100%;
  height: 180px;
  //position: fixed;
  left: 0px;
  // display: flex;
  flex: 1;
}


//// 改变pop框颜色
//.el-popover {
//  background: #000022;
//  border-color: #000022;
//  border-radius: 10px;
//  opacity: 0.92;
//  color: #fff;
//}


</style>
