// 检测是否是微信浏览器
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import '@/assets/fonts.css'
// import 'lib-flexible/flexible.js'
import '@/utils/rem.js';

import ElementUI from 'element-ui';
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

// 引入Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/gloableCss/popper.css'

// 引入Bootstrap JS（如果需要）
// import 'bootstrap/dist/js/bootstrap.bundle.min.js'

// main.js 或者相应的入口文件
import VueLazyload from 'vue-lazyload';

Vue.use(VueLazyload, {
  preload: 1.3, // 表示lazyload元素距离底部距离百分比
  loading: require('@/assets//Loading.svg'), // 正在加载时显示的图片路径
  error: require('@/assets/404.svg'), // 图片加载失败时显示的图片路径
  attempt: 5, // 图片加载失败后重试次数，默认3
  });

Vue.config.productionTip = false

// main.js 或者其他的初始化文件
import VueAMap from 'vue-amap';

Vue.use(VueAMap);

VueAMap.initAMapApiLoader({
  key: '6239898c80aeb98d11ef4f68faafafa0',
  plugin: [
    'AMap.Autocomplete',
    'AMap.PlaceSearch',
    'AMap.Scale',
    'AMap.OverView',
    'AMap.ToolBar',
    'AMap.MapType',
    'AMap.PolyEditor',
    'AMap.CircleEditor',
    'AMap.Geolocation'
  ],
  v: '1.4.4'
});
window._AMapSecurityConfig={
  securityCode:'9f9b8eb7a21f6f884b4009ac0dce401b'
}

//设置超时时间
axios.defaults.timeout = 5000
//设置api地址
//全局定义axios
// axios.defaults.baseURL = 'http://192.168.3.100:8081/api/ago/'
axios.defaults.baseURL = "https://www.baisptech.com/baisp/api/ago/"
// axios.defaults.baseURL = "http://localhost:8081/api/ago"
Vue.prototype.$http = axios

// Vue.prototype.$apiUrl = 'http://116.205.170.48:8081'

new Vue({
  router,
  store,
  ElementUI,
  render: h => h(App)
}).$mount('#app')
